import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import NewPayEventTable from "./components/NewPayEventTable";
import NewPayEventSettings from "./components/NewPayEventSettings";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { useNavigate, useParams } from "react-router-dom";

const NewPayEventPage = () => {
  const { isReAuthCompleted } = useLoginPopup();
  const [settingsData, setSettingsData] = useState(null);
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { dbId, lang } = useParams();

  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customDates, setCustomDates] = useState(false);

  const formatDate = (isoString) => isoString.split("T")[0];

  const formatDisplayDate = (isoString) => {
    const date = new Date(isoString);
    return isNaN(date.getTime()) ? "" : date.toLocaleDateString("en-GB");
  };

  const fetchData = async () => {
    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/payroll/get-new-pay-event-data?BaseHostURL=s1.olivs.com`,
      method: "GET",
      onSuccess: (data) => {
        setSettingsData(data);

        setSelectedGroup(data.ListOfPayEventGroups[0]?.prcID.toString() || "");
        setSelectedPeriod(data.ListOfPayEventPeriods[0]?.Value || "");
        setPaymentDate(formatDate(data.DefaultDates.PayDate));
        setStartDate(formatDate(data.DefaultDates.StartDate));
        setEndDate(formatDate(data.DefaultDates.EndDate));
        setCustomDates(false);
      },
      onError: (error) => {
        console.error("Error fetching pay event data:", error);
        enqueueSnackbar("Failed to load pay event data.", {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    if (isReAuthCompleted) {
      fetchData();
    }
  }, [isReAuthCompleted]);

  const handleCreatePayEvent = async () => {
    if (
      !selectedGroup ||
      !selectedPeriod ||
      !paymentDate ||
      !startDate ||
      !endDate
    ) {
      enqueueSnackbar("Please fill in all required fields.", {
        variant: "warning",
      });
      return;
    }

    // Construct the payload
    const payload = {
      StartDate: new Date(startDate).toISOString(),
      EndDate: new Date(endDate).toISOString(),
      PayDate: new Date(paymentDate).toISOString(),
      PayPeriod: selectedPeriod,
      PayCycle_prcID_0All_Negative1000ForEmpty: parseInt(selectedGroup, 10),
    };

    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/create-pay-event`,
      method: "POST",
      body: payload,
      onSuccess: (response) => {
        enqueueSnackbar("Pay event created successfully!", {
          variant: "success",
        });

        navigate(`/${dbId}/${lang}/payroll/pay-event-open/${response.PevID}`);
      },
      onError: (error) => {
        console.error("Error creating pay event:", error);
        enqueueSnackbar("Failed to create pay event.", {
          variant: "error",
        });
      },
    });
  };

  // Display error message if SuccessYN is "N" during initial fetch
  const renderSettings = () => {
    if (settingsData.SuccessYN !== "Y") {
      return (
        <Box
          sx={{
            padding: "20px",
            backgroundColor: "#ffe6e6",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" color="error">
            Error: {settingsData.ErrorMessage || "An unknown error occurred."}
          </Typography>
        </Box>
      );
    }

    return (
      <NewPayEventSettings
        listOfPayEventGroups={settingsData.ListOfPayEventGroups}
        listOfPayEventPeriods={settingsData.ListOfPayEventPeriods}
        defaultDates={settingsData.DefaultDates}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        paymentDate={paymentDate}
        setPaymentDate={setPaymentDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        customDates={customDates}
        setCustomDates={setCustomDates}
      />
    );
  };

  // Prepare data for the table by formatting API data
  const getFormattedDraftPayEvents = () => {
    if (!settingsData || !settingsData.ListOfDraftPayEvents) return [];

    return settingsData.ListOfDraftPayEvents.map((event) => ({
      id: event.pevID,
      createdOn: formatDisplayDate(event.pevCreatedDateTime),
      createdBy: event.usrCode,
      employeeCount: event.pevEmpCount,
      totalNet: event.pevNetPay,
      endDate: formatDisplayDate(event.pevEndDate),
      period: event.PayPeriod,
      payDate: formatDisplayDate(event.pevPayDate),
    }));
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        New Pay Event
      </Typography>

      {isReAuthCompleted &&
        (settingsData ? renderSettings() : <CircularProgress />)}

      <Box sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontWeight: 700 }}
          onClick={handleCreatePayEvent}
        >
          Create Pay Event
        </Button>
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        <Typography variant="h6" gutterBottom>
          Draft Pay Events
        </Typography>

        <NewPayEventTable data={getFormattedDraftPayEvents()} />
      </Box>
    </Box>
  );
};

export default NewPayEventPage;
