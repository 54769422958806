import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";

const EmployeeSelfService = () => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  // Fetch employee data on component mount
  useEffect(() => {
    const fetchEmployeeData = async () => {
      setLoadingData(true);
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/employee-self-service/get-employee-data-async`;
      try {
        await apiCall({
          url: url,
          method: "GET",
          onSuccess: (data) => {
            const {
              LocalUser,
              UserAddress,
              NextOfKinPerson,
              NextOfKinPersonAddress,
              Employee,
              SelectionLists,
            } = data.EmployeeData;

            // Extract only the required fields for the payload
            const limitedLocalUser = {
              usrFirstName: LocalUser.usrFirstName || "",
              usrLastName: LocalUser.usrLastName || "",
              usrFullName: LocalUser.usrFullName || "",
              usrEmail: LocalUser.usrEmail || "",
              usrPrivateEmail: LocalUser.usrPrivateEmail || "",
              usrNickName: LocalUser.usrNickName || "",
              usrPhone: LocalUser.usrPhone || "",
              usrGender: LocalUser.usrGender || "",
              usrCountry: LocalUser.usrCountry || "",
              usrLang: LocalUser.usrLang || "",
              usrPrefix: LocalUser.usrPrefix || "",
              usrDOB: LocalUser.usrDOB || "",
              usrOtherNames: LocalUser.usrOtherNames || "",
              usrPrevFirstName: LocalUser.usrPrevFirstName || "",
              usrPrevLastName: LocalUser.usrPrevLastName || "",
              usrPrevOtherNames: LocalUser.usrPrevOtherNames || "",
              usrVisaExpDate: LocalUser.usrVisaExpDate || "",
              usrWorkRights_ewrID: LocalUser.usrWorkRights_ewrID || 0,
            };

            const limitedUserAddress = {
              adrStreet1: UserAddress.adrStreet1 || "",
              adrStreet2: UserAddress.adrStreet2 || "",
              adrTown: UserAddress.adrTown || "",
              adrRegion: UserAddress.adrRegion || "",
              adrPostCode: UserAddress.adrPostCode || "",
              adrCountry: UserAddress.adrCountry || "",
              adrAddressee: UserAddress.adrAddressee || "",
              adrType_Enum5: UserAddress.adrType_Enum5 || 0,
            };

            const limitedNextOfKinPerson = {
              pplFirstName: NextOfKinPerson.pplFirstName || "",
              pplLastName: NextOfKinPerson.pplLastName || "",
              pplOtherNames: NextOfKinPerson.pplOtherNames || "",
              pplPrefix: NextOfKinPerson.pplPrefix || "",
              pplGender: NextOfKinPerson.pplGender || "",
              pplDOB: NextOfKinPerson.pplDOB || "",
              pplWorkEmail: NextOfKinPerson.pplWorkEmail || "",
              pplWorkEmailUse_Enum9: NextOfKinPerson.pplWorkEmailUse_Enum9 || 0,
              pplPrivEmail: NextOfKinPerson.pplPrivEmail || "",
              pplWorkPhone: NextOfKinPerson.pplWorkPhone || "",
              pplPrivPhone: NextOfKinPerson.pplPrivPhone || "",
              pplPosition_Enum10: NextOfKinPerson.pplPosition_Enum10 || 0,
              pplSalutation: NextOfKinPerson.pplSalutation || "",
            };

            const limitedNextOfKinPersonAddress = {
              adrStreet1: NextOfKinPersonAddress.adrStreet1 || "",
              adrStreet2: NextOfKinPersonAddress.adrStreet2 || "",
              adrTown: NextOfKinPersonAddress.adrTown || "",
              adrRegion: NextOfKinPersonAddress.adrRegion || "",
              adrPostCode: NextOfKinPersonAddress.adrPostCode || "",
              adrCountry: NextOfKinPersonAddress.adrCountry || "",
              adrAddressee: NextOfKinPersonAddress.adrAddressee || "",
              adrType_Enum5: NextOfKinPersonAddress.adrType_Enum5 || 0,
            };

            const limitedEmployee = {
              empTFN: Employee.empTFN || "",
              empLongSrvNo: Employee.empLongSrvNo || "",
              empWHEarnings: Employee.empWHEarnings || 0,
              empAccountName: Employee.empAccountName || "",
              empBankCode: Employee.empBankCode || "",
              empAccountNo: Employee.empAccountNo || "",
              empSF_psfID: Employee.empSF_psfID,
              //   empSuper: Employee.empSuper || 0,
              empSFMembNo: Employee.empSFMembNo || "",
              empSavAccName: Employee.empSavAccName || "",
              empSavBankCode: Employee.empSavBankCode || "",
              empSavAccNo: Employee.empSavAccNo || "",
              empSavAmt: Employee.empSavAmt || 0,
              empTaxTreatmentCode: Employee.empTaxTreatmentCode || "",
              empHelpDebt: Employee.empHelpDebt || "N",
            };

            // Set form data with only the required fields
            setFormData({
              LocalUser: limitedLocalUser,
              UserAddress: limitedUserAddress,
              NextOfKinPerson: limitedNextOfKinPerson,
              NextOfKinPersonAddress: limitedNextOfKinPersonAddress,
              Employee: limitedEmployee,
            });
            setSelectionLists(SelectionLists);
          },
          onError: (error) => {
            enqueueSnackbar(error || "Failed to fetch employee data", {
              variant: "error",
            });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      } finally {
        setLoadingData(false);
      }
    };

    fetchEmployeeData();
  }, [apiCall, enqueueSnackbar]);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/employee-self-service/save-employee-data-async`;

    // Prepare the payload with only the required fields
    const payload = {
      LocalUser: formData.LocalUser,
      UserAddress: formData.UserAddress,
      NextOfKinPerson: formData.NextOfKinPerson,
      NextOfKinPersonAddress: formData.NextOfKinPersonAddress,
      Employee: formData.Employee,
    };

    try {
      await apiCall({
        url: url,
        method: "POST",
        body: payload,
        onSuccess: () => {
          enqueueSnackbar("Employee data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error || "Failed to update employee data", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  return (
    <Box p={3}>
      {/* Show loading indicator while fetching data */}
      {loadingData && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {/* Display form only if data is loaded */}
      {formData && !loadingData && (
        <Box mt={3}>
          <Grid container spacing={3}>
            {/*  */}
            {/* Local User Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Personal Information</Typography>
              <TextField
                fullWidth
                label="First Name"
                value={formData.LocalUser.usrFirstName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFirstName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.LocalUser.usrLastName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrLastName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Full Name"
                value={formData.LocalUser.usrFullName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrFullName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                value={formData.LocalUser.usrEmail || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrEmail", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Private Email"
                value={formData.LocalUser.usrPrivateEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrivateEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Nickname"
                value={formData.LocalUser.usrNickName || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrNickName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone"
                value={formData.LocalUser.usrPhone || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPhone", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfGenders || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfGenders?.find(
                    (gender) => gender.Value === formData.LocalUser.usrGender,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrGender",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Gender"
                    margin="normal"
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfLanguages || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfLanguages?.find(
                    (lang) => lang.Value === formData.LocalUser.usrLang,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrLang",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Language"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Prefix"
                value={formData.LocalUser.usrPrefix || ""}
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrPrefix", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={
                  formData.LocalUser.usrDOB
                    ? formData.LocalUser.usrDOB.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("LocalUser", "usrDOB", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Additional Fields */}
              <TextField
                fullWidth
                label="Other Names"
                value={formData.LocalUser.usrOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous First Name"
                value={formData.LocalUser.usrPrevFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous Last Name"
                value={formData.LocalUser.usrPrevLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Previous Other Names"
                value={formData.LocalUser.usrPrevOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrPrevOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Visa Expiry Date"
                type="date"
                value={
                  formData.LocalUser.usrVisaExpDate
                    ? formData.LocalUser.usrVisaExpDate.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrVisaExpDate",
                    e.target.value,
                  )
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <Autocomplete
                fullWidth
                options={selectionLists.ListOfWorkingRights || []}
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists.ListOfWorkingRights?.find(
                    (item) =>
                      item.Value === formData.LocalUser.usrWorkRights_ewrID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "LocalUser",
                    "usrWorkRights_ewrID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Work Rights"
                    margin="normal"
                  />
                )}
              />
            </Grid>

            {/* User Address Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Address Information</Typography>
              <TextField
                fullWidth
                label="Street 1"
                value={formData.UserAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet1", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.UserAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrStreet2", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Town"
                value={formData.UserAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrTown", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Region"
                value={formData.UserAddress.adrRegion || ""}
                onChange={(e) =>
                  handleInputChange("UserAddress", "adrRegion", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Post Code"
                value={formData.UserAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "UserAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value === formData.UserAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "UserAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Country"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Addressee"
                value={formData.UserAddress.adrAddressee || ""}
                onChange={(e) =>
                  handleInputChange(
                    "UserAddress",
                    "adrAddressee",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfAddressTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfAddressTypes?.find(
                    (type) => type.Value === formData.UserAddress.adrType_Enum5,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "UserAddress",
                    "adrType_Enum5",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Address Type"
                    margin="normal"
                  />
                )}
              />
            </Grid>

            {/* Next of Kin Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Next of Kin Information</Typography>
              <TextField
                fullWidth
                label="First Name"
                value={formData.NextOfKinPerson.pplFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.NextOfKinPerson.pplLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Private Email"
                value={formData.NextOfKinPerson.pplPrivEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Phone"
                value={formData.NextOfKinPerson.pplPrivPhone || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivPhone",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfGenders || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfGenders?.find(
                    (gender) =>
                      gender.Value === formData.NextOfKinPerson.pplGender,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplGender",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Gender"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Prefix"
                value={formData.NextOfKinPerson.pplPrefix || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrefix",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                value={
                  formData.NextOfKinPerson.pplDOB
                    ? formData.NextOfKinPerson.pplDOB.split("T")[0]
                    : ""
                }
                onChange={(e) =>
                  handleInputChange("NextOfKinPerson", "pplDOB", e.target.value)
                }
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              {/* Additional Fields */}
              <TextField
                fullWidth
                label="Other Names"
                value={formData.NextOfKinPerson.pplOtherNames || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplOtherNames",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Work Email"
                value={formData.NextOfKinPerson.pplWorkEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplWorkEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Work Phone"
                value={formData.NextOfKinPerson.pplWorkPhone || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplWorkPhone",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    ?.ListOfPositions || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfPositions?.find(
                    (position) =>
                      position.Value ===
                      formData.NextOfKinPerson.pplPosition_Enum10,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPosition_Enum10",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Position"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Salutation"
                value={formData.NextOfKinPerson.pplSalutation || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplSalutation",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>

            {/* Next of Kin Address Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Next of Kin Address</Typography>
              <TextField
                fullWidth
                label="Street 1"
                value={formData.NextOfKinPersonAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet1",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.NextOfKinPersonAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet2",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Town"
                value={formData.NextOfKinPersonAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrTown",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Region"
                value={formData.NextOfKinPersonAddress.adrRegion || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrRegion",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Post Code"
                value={formData.NextOfKinPersonAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value ===
                      formData.NextOfKinPersonAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Country"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Addressee"
                value={formData.NextOfKinPersonAddress.adrAddressee || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrAddressee",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueInteger
                    .ListOfAddressTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueInteger?.ListOfAddressTypes?.find(
                    (type) =>
                      type.Value ===
                      formData.NextOfKinPersonAddress.adrType_Enum5,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrType_Enum5",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Address Type"
                    margin="normal"
                  />
                )}
              />
            </Grid>

            {/* Employee Section */}
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="h6">Employment Details</Typography>
              <TextField
                fullWidth
                label="Tax File Number"
                value={formData.Employee.empTFN || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empTFN", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Long Service Number"
                value={formData.Employee.empLongSrvNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empLongSrvNo", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Withholding Earnings"
                type="number"
                value={formData.Employee.empWHEarnings || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empWHEarnings", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Account Name"
                value={formData.Employee.empAccountName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empAccountName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Bank Code"
                value={formData.Employee.empBankCode || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empBankCode", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Account Number"
                value={formData.Employee.empAccountNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empAccountNo", e.target.value)
                }
                margin="normal"
              />
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfSuperTypes || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfSuperTypes?.find(
                    (fund) => fund.Value === formData.Employee.empSuper,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empSuper",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Superfund type"
                    margin="normal"
                  />
                )}
              />
              {/* <Autocomplete
                fullWidth
                options={selectionLists.ListOfSuperfunds || []}
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists.ListOfSuperfunds?.find(
                    (item) => item.Value === formData.Employee.empSF_psfID,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "Employee",
                    "empSF_psfID",
                    newValue ? newValue.Value : 0,
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Superfund"
                    margin="normal"
                  />
                )}
              /> */}
              <TextField
                fullWidth
                label="Super Fund Membership Number"
                value={formData.Employee.empSFMembNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSFMembNo", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Account Name"
                value={formData.Employee.empSavAccName || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAccName", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Bank Code"
                value={formData.Employee.empSavBankCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empSavBankCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Account Number"
                value={formData.Employee.empSavAccNo || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAccNo", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Savings Amount"
                type="number"
                value={formData.Employee.empSavAmt || ""}
                onChange={(e) =>
                  handleInputChange("Employee", "empSavAmt", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Tax Treatment Code"
                value={formData.Employee.empTaxTreatmentCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "Employee",
                    "empTaxTreatmentCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* Radio Buttons for "empHelpDebt" */}
              <FormLabel component="legend" style={{ marginTop: "16px" }}>
                HELP Debt
              </FormLabel>
              <RadioGroup
                row
                name="empHelpDebt"
                value={formData.Employee.empHelpDebt || "N"}
                onChange={(e) =>
                  handleInputChange("Employee", "empHelpDebt", e.target.value)
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmployeeSelfService;
