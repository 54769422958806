import React from "react";
import { useTheme } from "@mui/material/styles";
import { ButtonOwnProps } from "@mui/material";

export interface IconWrapperProps {
  color: ButtonOwnProps["color"];
  palleteType?: "main" | "light" | "dark" | "contrastText";
  children: (color: string) => React.ReactNode;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({
  color,
  palleteType = "contrastText",
  children,
}) => {
  const theme = useTheme();
  const strokeColor = theme.palette[color ?? "primary"][palleteType];

  return <>{children(strokeColor)}</>;
};
