import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { CustomReportsParameters } from "../../../../types/api/custom-reports";
import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";
import { YNToBool } from "../../../../utils/format-bool";

export const CustomReportsSingleSelection = ({
  value,
  handleOnChange,
  parameter,
  isFieldValid = true,
  errorMessage = "",
}: {
  parameter: CustomReportsParameters;
  value: any;
  handleOnChange: any;
  isFieldValid: boolean;
  errorMessage: string;
}) => {
  const { isDarkMode } = useTheme();

  const options = parameter.SelectionOptions || [];
  const val = options.find((o) => o.Value === value);

  return (
    <FormControl size="small" variant="outlined">
      <Autocomplete
        loading={false}
        multiple={false}
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            padding: "8.5px 0 8.5px 4px",
          },
        }}
        id={parameter.Label}
        disableClearable
        options={options}
        getOptionLabel={(option) => `${option.Label} `}
        filterOptions={(options, state) => {
          const filterRegex = new RegExp(state.inputValue, "i");
          return options.filter(
            (option) =>
              filterRegex.test(option.Label) || filterRegex.test(option?.Code),
          );
        }}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={option.Value}>
            <Box>
              <div>{option.Label}</div>
            </Box>
          </ListItem>
        )}
        value={val}
        onChange={(_, newValue) =>
          handleOnChange(`p${parameter.Order}`, newValue.Value)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={YNToBool(parameter.RequiredYN)}
            label={parameter.Label}
            sx={{
              label: {
                fontSize: 12,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
              minWidth: "240px",
              maxWidth: "240px",
            }}
            error={!isFieldValid}
            helperText={errorMessage}
          />
        )}
      />
    </FormControl>
  );
};
