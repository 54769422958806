import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";

const UserNextOfKin = ({ selectedUser }) => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/save-user-data-async`;
    try {
      await apiCall({
        url: url,
        method: "POST",
        body: formData,
        onSuccess: () => {
          enqueueSnackbar("Next of Kin data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error || "Failed to update Next of Kin data", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  useEffect(() => {
    if (!selectedUser) {
      setFormData(null);
      return;
    }

    const fetchUserData = async () => {
      setLoadingUserData(true);
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/users/get-user-data-async?localUserId=${selectedUser.usrID}`;
      try {
        await apiCall({
          url,
          method: "GET",
          onSuccess: (data) => {
            const { NextOfKinPerson, NextOfKinPersonAddress } = data.UserData;

            const handleDate = (dateString) => {
              if (!dateString) return "";
              const datePart = dateString.split("T")[0];
              if (datePart === "0001-01-01") {
                return "";
              }
              return dateString;
            };

            const updatedNextOfKinPerson = {
              ...NextOfKinPerson,
              pplDOB: handleDate(NextOfKinPerson.pplDOB),
            };

            setFormData({
              NextOfKinPerson: updatedNextOfKinPerson,
              NextOfKinPersonAddress,
            });
            setSelectionLists(data.UserData.SelectionLists);
          },
          onError: (error) => {
            enqueueSnackbar(error || "Failed to fetch user data", {
              variant: "error",
            });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [apiCall, enqueueSnackbar, selectedUser]);

  const footerTextCreated = formData?.NextOfKinPerson?.pplCreatedByFullName
    ? `${formData.NextOfKinPerson.pplCreatedByFullName}`
    : "";
  const footerTextUpdated = formData?.NextOfKinPerson?.pplUpdatedByFullName
    ? `- ${formData?.NextOfKinPerson.pplUpdatedByFullName}.`
    : ".";
  const footerText = footerTextCreated + footerTextUpdated;

  return (
    <Box p={3}>
      {/* Show loading indicator while fetching user data */}
      {loadingUserData && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {/* Display form only if user data is loaded */}
      {formData && !loadingUserData && (
        <Box mt={3}>
          <Grid container spacing={3}>
            {/* Section 1: Personal Details */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Personal Details</Typography>
              <TextField
                fullWidth
                label="Prefix"
                value={formData.NextOfKinPerson.pplPrefix || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrefix",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="First Name"
                value={formData.NextOfKinPerson.pplFirstName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplFirstName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.NextOfKinPerson.pplLastName || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplLastName",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* Gender Radio Buttons */}
              <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-label="pplGender"
                  name="pplGender"
                  value={formData.NextOfKinPerson.pplGender || ""}
                  onChange={(e) =>
                    handleInputChange(
                      "NextOfKinPerson",
                      "pplGender",
                      e.target.value,
                    )
                  }
                >
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="O"
                    control={<Radio />}
                    label="Not provided / Unknown"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                fullWidth
                label="Email"
                value={formData.NextOfKinPerson.pplPrivEmail || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivEmail",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                type="tel"
                label="Contact Phone Number"
                value={formData.NextOfKinPerson.pplPrivPhone || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplPrivPhone",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>

            {/* Section 2: Address */}
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6">Address</Typography>
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfCountries || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                    (country) =>
                      country.Value ===
                      formData.NextOfKinPersonAddress.adrCountry,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrCountry",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="Country"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Street 1"
                value={formData.NextOfKinPersonAddress.adrStreet1 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet1",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Street 2"
                value={formData.NextOfKinPersonAddress.adrStreet2 || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrStreet2",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Suburb / Town"
                value={formData.NextOfKinPersonAddress.adrTown || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrTown",
                    e.target.value,
                  )
                }
                margin="normal"
              />
              {/* State field: Conditional rendering based on country */}
              {formData.NextOfKinPersonAddress.adrCountry === "au" ? (
                <Autocomplete
                  fullWidth
                  options={
                    selectionLists?.DictionaryOfDisplayValueString
                      ?.ListOfAUStates || []
                  }
                  getOptionLabel={(option) => option.Display || ""}
                  value={
                    selectionLists?.DictionaryOfDisplayValueString?.ListOfAUStates?.find(
                      (state) =>
                        state.Value ===
                        formData.NextOfKinPersonAddress.adrRegion,
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    handleInputChange(
                      "NextOfKinPersonAddress",
                      "adrRegion",
                      newValue ? newValue.Value : "",
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ".MuiInputBase-root": {
                          borderRadius: "8px",
                          paddingTop: "8px !important",
                          paddingBottom: "9px !important",
                        },
                        label: {
                          ...commonThemes.inputLabel,
                          color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                      label="State"
                      margin="normal"
                    />
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  label="State"
                  value={formData.NextOfKinPersonAddress.adrRegion || ""}
                  onChange={(e) =>
                    handleInputChange(
                      "NextOfKinPersonAddress",
                      "adrRegion",
                      e.target.value,
                    )
                  }
                  margin="normal"
                />
              )}
              <TextField
                fullWidth
                label="Post Code"
                value={formData.NextOfKinPersonAddress.adrPostCode || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrPostCode",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Update
            </Button>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                width: "100%",
              }}
            >
              <Typography fontSize="12px" sx={{ textAlign: "right" }}>
                {footerText}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserNextOfKin;
