import React, { useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { usePayEventSummaryFinalised } from "../../features/payroll/api/get-pay-event-summary-finalised-page";
import { PageTitle } from "../../components/page-title";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PayrollEventDetails } from "../../features/payroll/components/PayrollEventDetails";
import { PayrollEventGrid } from "../../features/payroll/components/PayrollEventGrid";
import { PayrollEventFinalisedActions } from "../../features/payroll/components/PayrollEventFinalisedActions";
import { enqueueSnackbar } from "notistack";
import {
  PayrollEventFilters,
  FilterType,
} from "../../features/payroll/components/PayrollEventFilters";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { useReopenPayEvent } from "../../features/payroll/api/reopen-pay-event";
import { usePayEventPaydataFile } from "../../features/payroll/api/get-pay-event-paydata-file";

export function PayrollEventFinalised() {
  const { payEventID, dbId } = useParams();
  const { isReAuthCompleted } = useLoginPopup();
  const [selectedFilter, setSelectedFilter] = useState<FilterType>("all");

  const payrollEvent = usePayEventSummaryFinalised({
    id: payEventID ? payEventID : "",
    enable: process.env.NODE_ENV === "development" ? true : isReAuthCompleted,
  });

  const reopenPayEvent = useReopenPayEvent({
    id: payEventID ? payEventID : "",
  });

  const payEventPaydataFile = usePayEventPaydataFile({
    id: payEventID ? payEventID : "",
  });

  const filteredDataSets = useMemo(() => {
    if (!payrollEvent.data) return {};

    const data = payrollEvent.data.GridPack.DataDT;
    return {
      all: data,
      warnings: data.filter((employee) => employee.Warnings > 0),
      zeroNetPay: data.filter((employee) => employee.NetPay === 0),
      inactive: data.filter((employee) => employee.empStatus !== "A"),
    };
  }, [payrollEvent.data]);

  const filteredEmployees = useMemo(
    () => filteredDataSets[selectedFilter] || [],
    [filteredDataSets, selectedFilter],
  );

  const handleFilterChange = useCallback((filter: FilterType) => {
    setSelectedFilter(filter);
  }, []);

  const handleSTP = useCallback(() => {
    enqueueSnackbar("STP action not implemented yet", { variant: "info" });
  }, []);

  const handleSendPayslips = useCallback(() => {
    enqueueSnackbar("Send Payslips action not implemented yet", {
      variant: "info",
    });
  }, []);

  const handleReopen = useCallback(() => {
    reopenPayEvent.reopenPayEvent();
  }, [reopenPayEvent]);

  const handleAbaFile = useCallback(() => {
    payEventPaydataFile.downloadPaydataFile();
  }, [payEventPaydataFile]);

  const handleSeeWarnings = useCallback(() => {
    window.open(
      `/${dbId}/en-au/reports/warnings?p0=2&p1=${payEventID}`,
      "_blank",
    );
  }, [payEventID, dbId]);

  const warningsCount = useMemo(() => {
    if (!payrollEvent.data) return 0;
    return payrollEvent.data.GridPack.DataDT.filter(
      (employee) => employee.Warnings > 0,
    ).length;
  }, [payrollEvent.data]);

  console.log("[DEBUGGING][PAYROLL EVENT FINALISED] data: ", payrollEvent.data);

  return (
    <>
      <BackdropLoading
        open={payrollEvent.isLoading || reopenPayEvent.isLoading}
      />
      <PageTitle title="Payroll Event Summary - Finalised" />

      {payrollEvent.data && (
        <>
          <PayrollEventDetails
            mainDatesTxt={payrollEvent.data.MainDatesTxt}
            createdTxt={payrollEvent.data.CreatedTxt}
            warnings={payrollEvent.data.Warnings}
            pevNotes={payrollEvent.data.PevNotes}
            pevPayslipComment={payrollEvent.data.PevPayslipComment}
            onPevNotesBlur={() => {}}
            onPeyPayslipCommentBlur={() => {}}
            finalized={true}
          />
          <PayrollEventFinalisedActions
            onSTP={handleSTP}
            onSendPayslips={handleSendPayslips}
            onReopen={handleReopen}
            onAbaFile={handleAbaFile}
            onSeeWarnings={handleSeeWarnings}
            warningsCount={warningsCount}
          />
          <PayrollEventGrid
            data={filteredEmployees}
            columns={payrollEvent.data.GridPack.ColumnsList}
            actionsList={payrollEvent.data.GridPack.ActionsList}
            tooltipsList={payrollEvent.data.GridPack.TooltipsList}
            onRefresh={() => payrollEvent.refetch()}
          />
        </>
      )}
    </>
  );
}
