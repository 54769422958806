import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { DataGrid, Column, SearchPanel } from "devextreme-react/data-grid";
import { Star, StarBorder } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import useApi from "../../../hooks/useApi";
import envConfig from "../../../config";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const AddPayItemDialog = ({
  dialogOpen,
  onClose,
  onSelect,
  listOfPayItems,
  payRate,
}) => {
  const { PevID, EmpID } = useParams();

  const { apiCall } = useApi();
  const [favorites, setFavorites] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDetailsOfPayEvent = async (selectedItem) => {
    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/pay-items/get-single-item?PayItemId=${selectedItem.ptiID}`,
      method: "GET",
      onSuccess: async (data) => {
        const payitem = data.Item;
        const newRow = {
          PedID: uuidv4(),
          PtiName: payitem.ptiName,
          // PedCreatedBy_usrID = SessionHelper.GetLocalUserID(),
          PedCreatedDateTime: new Date(),
          PttGroup: payitem.ptiType_pttID,
          PedPayroll_pevID: selectedItem.ptiID,
          PtiOrder: 0,
          PedDiagnostics: "",
          PedEmployee_empID: EmpID,
          MainEarningRecord: "",
          PedComment: "",
          PedExpense_AccNo: payitem.ptiExp_accNo,
          // PedSite_dpsID = Employee.LocalUser.usrSite_dpsID,
          PtiTaxW: payitem.ptiTaxW,
          PtiLeave: payitem.ptiLeave,
          PtiSuper: payitem.ptiSuper,
          PtiCalcFactor: payitem.ptiCalcFactor,
          PtiCalcType: payitem.ptiCalcType,
          PtiType_pttID: payitem.ptiType_pttID,
          PedRecordStatus: "M",
          PedPayItem_ptiID: payitem.ptiID,
          PedShowComment: "Y",
          PedRate: dialogOpen === 3 ? payRate * -1 : payRate,
          PedUnits: 0,
          PedValue: 0,
          PedManualCalcYN: "N",
          RecalculatedYN: "N",
        };
        await onSelect(newRow, dialogOpen);
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    if (listOfPayItems) {
      const { DataDT, ColumnsList } = listOfPayItems;

      // Map RpcDataType_rpdID to DevExtreme data types
      const dataTypeMap = {
        1: "string",
        2: "number",
        3: "date",
        4: "number",
      };

      setColumns(
        ColumnsList.filter((col) => col.RpcVisible === "Y").map((col) => ({
          dataField: col.RpcDataSource,
          caption: col.RpcHeader,
          dataType: dataTypeMap[col.RpcDataType_rpdID],
          allowSorting: col.RpcSort === "Y",
          allowFiltering: col.RpcFilter === "Y",
          alignment:
            col.RpcAlign === 1
              ? "left"
              : col.RpcAlign === 2
                ? "center"
                : "right",
        })),
      );

      const favItems = DataDT.filter((item) => item.ptiFavYN === "Y");
      setFavorites(favItems);
      setAllItems(DataDT);
    }
  }, [listOfPayItems]);

  const handleFavoriteToggle = async (item) => {
    setLoading(true);
    const newFavYN = item.ptiFavYN === "Y" ? "N" : "Y";

    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-list-of-pay-items-add-to-pay-event?PtiID=${item.ptiID}&FavYN=${newFavYN}`,
      method: "GET",
      onSuccess: () => {
        // Update the item's favorite status in allItems
        const updatedAllItems = allItems.map((i) =>
          i.ptiID === item.ptiID ? { ...i, ptiFavYN: newFavYN } : i,
        );
        setAllItems(updatedAllItems);

        // Update favorites list
        if (newFavYN === "Y") {
          setFavorites([...favorites, { ...item, ptiFavYN: "Y" }]);
        } else {
          setFavorites(favorites.filter((i) => i.ptiID !== item.ptiID));
        }
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const renderStarIcon = (cellInfo) => {
    const isFavorite = cellInfo.data.ptiFavYN === "Y";

    return (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleFavoriteToggle(cellInfo.data);
        }}
        size="small"
      >
        {isFavorite ? (
          <Star color="primary" fontSize="small" />
        ) : (
          <StarBorder fontSize="small" />
        )}
      </IconButton>
    );
  };

  const onRowClick = async (e) => {
    // Ignore clicks on the star icon
    if (e.event.target.closest("button")) return;
    setLoading(true);
    await getDetailsOfPayEvent(e.data);
  };

  return (
    <Dialog open={!!dialogOpen} onClose={onClose} maxWidth="md" fullWidth>
      <BackdropLoading open={loading} />
      <DialogTitle>Select Pay Item</DialogTitle>
      <DialogContent>
        {favorites.length > 0 && (
          <Box mb={2}>
            <DataGrid
              width="100%"
              dataSource={favorites}
              keyExpr="ptiID"
              showBorders
              hoverStateEnabled
              onRowClick={onRowClick}
            >
              <SearchPanel visible={true} />
              <Column
                dataField="ptiFavYN"
                caption=""
                cellRender={renderStarIcon}
                width={70}
                alignment="center"
              />
              {columns.map((col) => (
                <Column key={col.dataField} {...col} />
              ))}
            </DataGrid>
          </Box>
        )}
        <DataGrid
          dataSource={allItems}
          keyExpr="ptiID"
          showBorders
          hoverStateEnabled
          onRowClick={onRowClick}
        >
          <SearchPanel visible={true} />
          <Column
            dataField="ptiFavYN"
            caption=""
            cellRender={renderStarIcon}
            width={70}
            alignment="center"
          />
          {columns.map((col) => (
            <Column key={col.dataField} {...col} />
          ))}
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPayItemDialog;
