import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

export const FormRadio = ({
  label,
  value,
  valueKey,
  onChange,
  loading,

  options,
  optionLabel,
  optionAccessorKey = "endID",
  formControlStyles = {},
  formRadioGroupStyles = {},
}) => {
  return (
    <FormControl sx={{ ...formControlStyles }}>
      <RadioGroup
        label={label}
        sx={{
          display: "flex",
          flexDirection: "row",
          ...formRadioGroupStyles,
        }}
        value={value ? value : null}
        defaultValue={undefined}
        onChange={(e) => {
          const id = e.target.value;
          onChange(valueKey, id);
        }}
      >
        {options?.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              disabled={loading}
              control={<Radio />}
              label={option?.[optionLabel]}
              value={option?.[optionAccessorKey]}
              // sx={{
              //   mr: 2,
              //   span: {
              //     ...commonThemes.normalText,
              //     color: isDarkMode ? '#fff' : 'rgba(0,0,0,0.6)',
              //   },
              // }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
