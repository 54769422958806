import React from "react";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { OlivsDataPicker } from "../../../../components/ui/date-picker";
import {
  darkTheme,
  lightTheme,
  commonThemes,
} from "../../../../utils/themes/themes";
import { useTheme } from "../../../../context/ThemeContext";
import { Arrows } from "../../../../components/arrows";
import { useBASStore } from "../store/bas";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  onCancelCallback: () => void;
  onApplyFilters: () => void;
}

dayjs.extend(quarterOfYear);

export const BASFiltersModal = ({
  isModalOpen,
  onClose,
  onCancelCallback,
  onApplyFilters,
}: Props) => {
  const { dbId, lang } = useParams();
  const navigate = useNavigate();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const isMobile = useMediaQuery("(max-width: 580px)");

  const unlockSettingsValue = useBASStore.use.unlockSettings();
  const setUnlockSettingsValue = useBASStore.use.setUnlockSettings();

  const cashMethod = useBASStore.use.accountingMethodCA();
  const setCashMethod = useBASStore.use.setAccountingMethodCA();

  const gstStartDate = useBASStore.use.gstStartDate();
  const paygStartDate = useBASStore.use.paygStartDate();
  const endDate = useBASStore.use.endDate();

  const setGstStartDate = useBASStore.use.setGstStartDate();
  const setPaygStartDate = useBASStore.use.setPaygStartDate();
  const setEndDate = useBASStore.use.setEndDate();

  const gstCycle = useBASStore.use.gstCycle();
  const paygCycle = useBASStore.use.paygCycle();
  const gstPeriod = useBASStore.use.gstPeriod();
  const paygPeriod = useBASStore.use.paygPeriod();

  const handleGstStartDateChange = (newStartDate) => {
    setGstStartDate(newStartDate);
  };

  const handlePaygStartDateChange = (newStartDate) => {
    setPaygStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate) => {
    setEndDate(newEndDate);
  };

  // TODO: Good
  const handleLeftDoubleArrowClick = () => {
    // Making an assumption that gstDate and paygDate depend on each other
    if (!gstStartDate || !paygStartDate || !endDate) return;
    const newGstStartDate = gstStartDate.subtract(1, "year");
    const newPaygStartDate = paygStartDate.subtract(1, "year");
    const newEndDate = endDate.subtract(1, "year").endOf("M");

    setGstStartDate(newGstStartDate);
    setPaygStartDate(newPaygStartDate);
    setEndDate(newEndDate);
  };

  const handleRightDoubleArrowClick = () => {
    // Making an assumption that gstDate and paygDate depend on each other
    if (!gstStartDate || !paygStartDate || !endDate) return;
    const newGstStartDate = gstStartDate.add(1, "year");
    const newPaygStartDate = paygStartDate.add(1, "year");
    const newEndDate = endDate.add(1, "year").endOf("M");

    setGstStartDate(newGstStartDate);
    setPaygStartDate(newPaygStartDate);
    setEndDate(newEndDate);
  };

  const adjustPrimaryDateBackward = (baseDate, unit) => {
    if (unit === "M") {
      const newEndDate = baseDate.subtract(1, unit).endOf(unit).endOf("M");
      const newStartDate = newEndDate
        .add(2, "D")
        .subtract(1, unit)
        .startOf("M");
      return { newEndDate, newStartDate };
    } else if (unit === "Q") {
      const newEndDate = baseDate.subtract(1, unit).endOf("M");
      const newStartDate = newEndDate
        .subtract(1, unit)
        .add(2, "D")
        .startOf("M");
      return { newEndDate, newStartDate };
    } else {
      const newEndDate = baseDate.subtract(1, unit).endOf("M");
      const newStartDate = newEndDate.subtract(1, unit);
      return { newEndDate, newStartDate };
    }
  };

  const adjustPrimaryDateForward = (baseDate, unit) => {
    if (unit === "M") {
      const newEndDate = baseDate.add(1, unit).endOf("M");
      const newStartDate = newEndDate
        .add(2, "D")
        .subtract(1, unit)
        .startOf("M");
      return { newStartDate, newEndDate };
    } else if (unit === "Q") {
      const newEndDate = baseDate.add(1, unit).endOf("M");
      const newStartDate = newEndDate
        .add(2, "D")
        .subtract(1, unit)
        .startOf("M");
      return { newStartDate, newEndDate };
    } else {
      const newEndDate = baseDate.add(1, unit).endOf("M");
      const newStartDate = baseDate.substract(1, unit);
      return { newStartDate, newEndDate };
    }
  };

  const adjustSecondaryDateBackward = (baseDate, unit) => {
    if (unit === "M") {
      return baseDate.add(2, "D").subtract(1, unit).startOf("M");
    } else if (unit === "Q") {
      return baseDate.subtract(1, unit).add(2, "D").startOf("M");
    } else {
      return baseDate.subtract(1, unit).endOf("M");
    }
  };

  const handleGstLeftArrowClick = () => {
    if (!endDate) return;

    const gstUnit = gstCycle === "A" ? "year" : (gstCycle as any);
    const paygUnit = paygCycle === "A" ? "year" : (paygCycle as any);

    const dates = adjustPrimaryDateBackward(endDate, gstUnit);

    setEndDate(dates.newEndDate);
    setGstStartDate(dates.newStartDate);

    const newPaygStartDate = adjustSecondaryDateBackward(
      dates.newEndDate,
      paygUnit,
    );

    setPaygStartDate(newPaygStartDate);
  };

  const handlePaygLeftArrowClick = () => {
    if (!endDate) return;

    const paygUnit = paygCycle === "A" ? "year" : (paygCycle as any);
    const gstUnit = gstCycle === "A" ? "year" : (gstCycle as any);

    const dates = adjustPrimaryDateBackward(endDate, paygUnit);

    setEndDate(dates.newEndDate);
    setPaygStartDate(dates.newStartDate);

    const newGstStartDate = adjustSecondaryDateBackward(
      dates.newEndDate,
      gstUnit,
    );

    setGstStartDate(newGstStartDate);
  };

  const handleGstRightArrowClick = () => {
    if (!endDate) return;

    const paygUnit = paygCycle === "A" ? "year" : (paygCycle as any);
    const gstUnit = gstCycle === "A" ? "year" : (gstCycle as any);

    const dates = adjustPrimaryDateForward(endDate, gstUnit);

    setEndDate(dates.newEndDate);
    setGstStartDate(dates.newStartDate);

    const newPaygStartDate = adjustSecondaryDateBackward(
      dates.newEndDate,
      paygUnit,
    );

    setPaygStartDate(newPaygStartDate);
  };

  const handlePaygRightArrowClick = () => {
    if (!endDate) return;

    const paygUnit = paygCycle === "A" ? "year" : (paygCycle as any);
    const gstUnit = gstCycle === "A" ? "year" : (gstCycle as any);

    const dates = adjustPrimaryDateForward(endDate, paygUnit);

    setEndDate(dates.newEndDate);
    setPaygStartDate(dates.newStartDate);

    const newGstStartDate = adjustSecondaryDateBackward(
      dates.newEndDate,
      gstUnit,
    );

    setGstStartDate(newGstStartDate);
  };

  const isGstCycle = gstCycle && gstCycle !== "";
  // const isGstCycle = (gstCycle && gstCycle !== "") || true;
  const isPaygCycle = paygCycle && paygCycle !== "";
  // const isPaygCycle = (paygCycle && paygCycle !== "") || true;

  const settingsPrefix = `/${dbId}/${lang}/settings#gst-details`;

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl", padding: isMobile ? 0 : 2 },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: theme.typography.fontFamily,
          // @ts-ignore TODO: remove it later
          fontSize: isMobile ? 18 : theme.h1.fontSize,
          // @ts-ignore TODO: remove it later
          fontWeight: theme.typography.fontWeightThin,
        }}
      >
        Business Activity Statement
      </DialogTitle>

      <DialogContent sx={{ overflow: "visible" }}>
        <FormControlLabel
          key={"UNCLOCK_SETTINGS"}
          value={unlockSettingsValue}
          control={
            <Checkbox
              checked={unlockSettingsValue}
              onChange={(e) => setUnlockSettingsValue(e.target.checked)}
              id="UNCLOCK_SETTINGS"
            />
          }
          label={"Unlock Settings"}
          // @ts-ignore
          sx={{
            flex: 1,
            marginBottom: "40px",
            // @ts-ignore
            span: { ...commonThemes.normalText },
          }}
        />
        {/* TODO: Turn div into the Box */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "19px",
          }}
        >
          <FormLabel
            sx={{
              // @ts-ignore
              ...theme.blueLabel,
            }}
          >
            {`GST - ${gstPeriod}`}
          </FormLabel>

          <Box
            sx={{
              display: "flex",
              justifyItems: "cemter",
              alignItems: "center",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <OlivsDataPicker
                label="Start Date"
                readOnly={!unlockSettingsValue}
                maxDate={endDate}
                value={gstStartDate}
                onChange={handleGstStartDateChange}
              />
              <OlivsDataPicker
                label="End Date"
                readOnly={!unlockSettingsValue}
                minDate={gstStartDate}
                value={endDate}
                onChange={handleEndDateChange}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyItems: "space-between",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormControlLabel
              key={"CASH_METHOD"}
              aria-readonly={!unlockSettingsValue}
              disabled={!unlockSettingsValue}
              value={cashMethod === "C"}
              control={
                <Checkbox
                  checked={cashMethod === "C"}
                  onChange={() => setCashMethod(cashMethod === "C" ? "A" : "C")}
                  id="CASH_METHOD"
                />
              }
              label={"Cash Method"}
              // @ts-ignore
              sx={{ flex: 1, span: { ...commonThemes.normalText } }}
            />
            {isGstCycle && (
              <Box sx={{ flex: 1 }}>
                <Arrows
                  onLeftArrowClick={handleGstLeftArrowClick}
                  onRightArrowClick={handleGstRightArrowClick}
                  onDoubleRightArrowClick={handleRightDoubleArrowClick}
                  onDoubleLeftArrowClick={handleLeftDoubleArrowClick}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ marginTop: "40px" }}>
            <FormLabel
              sx={{
                // @ts-ignore
                ...theme.blueLabel,
              }}
            >
              {`PAYG Withholding - ${paygPeriod}`}
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                justifyItems: "cemter",
                alignItems: "center",
                gap: "8px",
                marginTop: "8px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <OlivsDataPicker
                  label="Start Date"
                  readOnly={!unlockSettingsValue}
                  maxDate={endDate}
                  value={paygStartDate}
                  onChange={handlePaygStartDateChange}
                />
                <OlivsDataPicker
                  label="End Date"
                  readOnly={!unlockSettingsValue}
                  minDate={paygStartDate}
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyItems: "space-between",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {/* Placeholder instead of the checkbox */}
              <Box sx={{ flex: 1, height: "42px" }} />
              {isPaygCycle && (
                <Box sx={{ flex: 1 }}>
                  <Arrows
                    onLeftArrowClick={handlePaygLeftArrowClick}
                    onRightArrowClick={handlePaygRightArrowClick}
                    onDoubleRightArrowClick={handleRightDoubleArrowClick}
                    onDoubleLeftArrowClick={handleLeftDoubleArrowClick}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <DialogActions
            sx={{
              pt: 0,
              mt: 5,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button
                onClick={() => navigate(settingsPrefix)}
                variant="text"
                sx={{
                  // @ts-ignore
                  color: theme.palette.blackWhite.main,
                  "&:hover": {
                    bgcolor: "rgba(132, 159, 35, 0.04)",
                  },
                }}
              >
                Go to Settings
              </Button>
            </Box>
            <Box>
              <Button
                sx={{
                  "&:hover": {
                    bgcolor: "rgba(132, 159, 35, 0.04)",
                  },
                }}
                onClick={() => {
                  onCancelCallback();
                  onClose();
                }}
                // @ts-ignore
                variant="secondary"
              >
                Cancel
              </Button>

              <Button
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: theme.palette.primary.main,
                  },
                }}
                // disabled={loading}
                onClick={async () => {
                  await onApplyFilters();
                  onClose();
                }}
                // @ts-ignore
                variant="contained"
              >
                Show Report
              </Button>
            </Box>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
};
