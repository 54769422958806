import { enqueueSnackbar } from "notistack";
import envConfig from "../config";
import { getCookie, getLoginSecured } from "./api";

export const downloadPDF = async (base64Data, filename) => {
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  return true;
};

export const downloadFile = (data, filename, type) => {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const toLowerCaseKeys = (obj) => {
  return Object.keys(obj).reduce(function (accumulator, key) {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
};

export const isEmptyOrNull = (value) => {
  return (
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
};

export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const createSessionForExternal = async (
  url,
  ip,
  openInNewWindow = false,
  apiCall,
  dbId,
) => {
  if (url.startsWith(`https://${envConfig.loginUrl}`)) {
    window.location = url;
    return;
  }

  const newSessionApiUrl = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;

  const sessionHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": ip,
    "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
    "browser-agent": window.navigator.userAgent,
    "session-id": getCookie("sessionId"),
    "api-key": getCookie("apiKey") + "-" + dbId,
  };

  const initialTokenData = {
    LoginSecuredHash: getLoginSecured(),
    CurrentHost: `https://${envConfig.mainServiceUrl}`,
    LoginPageUrl: `https://${envConfig.loginUrl}`,
    RedirectPageUrl: url,
    IpAddress: ip,
    UserAgentAkaBrowserDetails: navigator.userAgent,
    ReferrerUrl: window.location.origin,
    DatabaseId: Number(dbId),
  };

  apiCall({
    url: newSessionApiUrl,
    method: "POST",
    headers: sessionHeaders,
    body: initialTokenData,
    onSuccess: (sessionManagementJson) => {
      if (openInNewWindow) {
        window.open(
          sessionManagementJson?.redirectPageUrl,
          "_blank",
          "noopener,noreferrer",
        );
      } else {
        window.location = sessionManagementJson?.redirectPageUrl;
      }
    },
    onError: (errorMessage) => {
      enqueueSnackbar(errorMessage, { variant: "error" });
    },
  });
};

export const createSessionToLogin = async (url, ip) => {
  const newSessionApiUrl = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;

  const sessionHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": ip,
    "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
    "browser-agent": window.navigator.userAgent,
    "session-id": getCookie("sessionId"),
    "api-key": getCookie("apiKey") + "-" + 0,
  };

  const initialTokenData = JSON.stringify({
    LoginSecuredHash: getLoginSecured(),
    DatabaseId: 0,
    CurrentHost: `https://${envConfig.mainServiceUrl}`,
    LoginPageUrl: `https://${envConfig.loginUrl}`,
    RedirectPageUrl: url,
    IpAddress: ip,
    UserAgentAkaBrowserDetails: navigator.userAgent,
    ReferrerUrl: window.location.origin,
  });
  const sessionManagementResponse = await fetch(newSessionApiUrl, {
    method: "POST",
    headers: sessionHeaders,
    body: initialTokenData,
  });

  const sessionManagementJson = await sessionManagementResponse.json();
  if (sessionManagementJson?.successYN === "Y") {
    window.location = sessionManagementJson?.redirectPageUrl;
  } else {
    enqueueSnackbar(
      sessionManagementJson.errorMessage || "Something went wrong",
      { variant: "error" },
    );
    window.location = `https://${envConfig.loginUrl}`;
    console.log("nav went wrong", sessionManagementJson);
  }
};

export const parseQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  const queryObject = {};

  for (const [key, value] of params.entries()) {
    queryObject[key] = value;
  }

  return queryObject;
};

export const getUrlForNewSession = async (
  url,
  apiCall,
  enqueueSnackbar,
  dbId,
) => {
  if (url.startsWith(`https://${envConfig.loginUrl}`)) {
    window.location = url;
    return;
  }

  const newSessionApiUrl = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;

  const sessionHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": "127.0.0.2",
    "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
    "browser-agent": window.navigator.userAgent,
    "session-id": getCookie("sessionId"),
    "api-key": getCookie("apiKey") + "-" + dbId,
  };

  const initialTokenData = {
    LoginSecuredHash: getLoginSecured(),
    CurrentHost: `https://${envConfig.mainServiceUrl}`,
    LoginPageUrl: `https://${envConfig.loginUrl}`,
    RedirectPageUrl: url,
    IpAddress: "127.0.0.2",
    UserAgentAkaBrowserDetails: navigator.userAgent,
    ReferrerUrl: window.location.origin,
    DatabaseId: Number(dbId),
  };

  return new Promise((resolve, reject) => {
    apiCall({
      url: newSessionApiUrl,
      method: "POST",
      headers: sessionHeaders,
      body: initialTokenData,
      onSuccess: (sessionManagementJson) => {
        console.log(sessionManagementJson);
        console.log(sessionManagementJson?.redirectPageUrl);
        if (sessionManagementJson?.redirectPageUrl) {
          resolve(sessionManagementJson.redirectPageUrl);
        } else {
          enqueueSnackbar("Redirect URL not found in the response.", {
            variant: "error",
          });
          reject("Redirect URL not found.");
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        reject(errorMessage);
      },
    });
  });
};
