import React, { useCallback, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { EmployeeListData, PayEventSummaryColumn } from "../types";
import DataGrid, {
  Column,
  Scrolling,
  Selection,
  SearchPanel,
} from "devextreme-react/cjs/data-grid";
import { SearchInput } from "../../../components/ui/inputs/search-input";
import {
  convertYNtoBool,
  formatGridCellValue,
  getColumnAlignment,
} from "../utils/grid-helpers";
import { useAddEmployeeToPayEvent } from "../api/add-employee-to-pay-event";

interface AddEmployeeToPayEventDialogProps {
  open: boolean;
  onClose: () => void;
  data: EmployeeListData[] | undefined;
  columns: PayEventSummaryColumn[] | undefined;
  isLoading: boolean;
  payEventId: number;
  onSuccess?: () => void;
}

export const AddEmployeeToPayEventDialog: React.FC<
  AddEmployeeToPayEventDialogProps
> = ({ open, onClose, data, columns, isLoading, payEventId, onSuccess }) => {
  const [selectedEmployee, setSelectedEmployee] =
    React.useState<EmployeeListData | null>(null);

  const gridRef = useRef<DataGrid<EmployeeListData>>(null);

  const addEmployeeToPayEvent = useAddEmployeeToPayEvent({
    onSuccess: () => {
      onClose();
      setSelectedEmployee(null);
      if (onSuccess) onSuccess();
    },
  });

  const handleSelectionChanged = useCallback(({ selectedRowsData }: any) => {
    const selected = selectedRowsData[0] as EmployeeListData;
    setSelectedEmployee(selected);
  }, []);

  const handleCreateRecord = useCallback(() => {
    if (selectedEmployee) {
      addEmployeeToPayEvent.addEmployee({
        empId: selectedEmployee.empID,
        pevId: payEventId,
      });
    }
  }, [selectedEmployee, addEmployeeToPayEvent, payEventId]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      gridRef.current?.instance.searchByText(e.target.value);
    },
    [],
  );

  const handleSearchClear = useCallback(() => {
    gridRef.current?.instance.searchByText("");
  }, []);

  const columnsList = columns || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Employee to Pay Event</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 2, display: "flex" }}>
          <Box sx={{ flex: 1 }} />
          <SearchInput
            onChange={handleSearchChange}
            onClear={handleSearchClear}
          />
        </Box>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            ref={gridRef}
            dataSource={data || []}
            showBorders={true}
            selection={{ mode: "single", showCheckBoxesMode: "always" }}
            onSelectionChanged={handleSelectionChanged}
            loadPanel={{ enabled: isLoading }}
          >
            <SearchPanel visible={false} />
            <Selection mode="single" showCheckBoxesMode="always" />
            <Scrolling mode="virtual" />

            {columnsList.map((column) => (
              <Column
                key={column.RpcHeader}
                dataField={column.RpcDataSource}
                caption={column.RpcHeader}
                alignment={getColumnAlignment(column.RpcAlign)}
                allowFiltering={convertYNtoBool(column.RpcFilter)}
                allowSorting={convertYNtoBool(column.RpcSort)}
                allowSearch={convertYNtoBool(column.RpcSearchable)}
                visible={convertYNtoBool(column.RpcVisible)}
                fixed={convertYNtoBool(column.RpcFreeze)}
                minWidth={column.RpcMinWidth}
                width={
                  column.RpcHeader === "Employee"
                    ? undefined
                    : column.RpcMaxWidth
                }
              />
            ))}
          </DataGrid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleCreateRecord}
          disabled={!selectedEmployee || addEmployeeToPayEvent.isLoading}
          variant="contained"
        >
          {addEmployeeToPayEvent.isLoading
            ? "Creating record..."
            : "Create Record"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
