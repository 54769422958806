import React, { useMemo } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { PayEventSummaryData } from "../types";

export type FilterType = "all" | "warnings" | "zeroNetPay" | "inactive";

interface PayrollEventFiltersProps {
  selectedFilter: FilterType;
  onFilterChange: (filter: FilterType) => void;
  data: PayEventSummaryData[];
}

const filterLabels: Record<FilterType, string> = {
  all: "All Employees",
  warnings: "Employees with Warnings",
  zeroNetPay: "Employees with $0 Net Pay",
  inactive: "Inactive Employees",
};

export const PayrollEventFilters: React.FC<PayrollEventFiltersProps> = ({
  selectedFilter,
  onFilterChange,
  data,
}) => {
  // Memoize the filter counts
  const filterCounts = useMemo(
    () => ({
      all: data.length,
      warnings: data.filter((employee) => employee.Warnings > 0).length,
      zeroNetPay: data.filter((employee) => employee.NetPay === 0).length,
      inactive: data.filter((employee) => employee.empStatus !== "A").length,
    }),
    [data],
  );

  return (
    <Box mt={4}>
      <Typography variant="body1" mb={2}>
        Filter Employees
      </Typography>
      <FormControl>
        <RadioGroup
          value={selectedFilter}
          onChange={(e) => onFilterChange(e.target.value as FilterType)}
          row
        >
          {(Object.keys(filterLabels) as FilterType[]).map((filter) => (
            <FormControlLabel
              key={filter}
              value={filter}
              control={<Radio />}
              label={`${filterLabels[filter]} (${filterCounts[filter]})`}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
