import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ImportTransactionFromCSVValidationAllRecordsModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  onCancel: () => void;
  onGenerateCSVReport: () => void;
  totalPartiallyInvalidRecords: number;
  totalTotallyInvalidRecords: number;
}

export const ImportTransactionFromCSVValidationAllRecordsModal = ({
  isModalOpen,
  onClose,
  onContinue,
  onCancel,
  onGenerateCSVReport,
  totalPartiallyInvalidRecords,
  totalTotallyInvalidRecords,
}: ImportTransactionFromCSVValidationAllRecordsModalProps) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle textAlign={"center"}>
        Import Transaction From CSV
      </DialogTitle>
      <DialogContent sx={{ overflow: "visible", maxWidth: 600 }}>
        <Box>
          Total of {totalTotallyInvalidRecords} rows will be skipped due to
          invalid Date or Amount.
          {totalPartiallyInvalidRecords > 0 ? (
            <>
              <br />
              <br />
              Total of {totalPartiallyInvalidRecords} rows have invalid data.
              You can stop and re-do the process, get CSV Report with report for
              each invalid row, or import transaction for manual processing
              later.
            </>
          ) : null}
        </Box>
        <DialogActions sx={{ pt: 0, mt: 5 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyItems={"center"}
            alignItems={"center"}
            gap={2}
            width={"100%"}
          >
            <Button
              onClick={onCancel}
              // @ts-ignore
              variant="outlined"
            >
              Go back
            </Button>
            <Button
              onClick={onContinue}
              // @ts-ignore
              variant="secondary"
            >
              Import valid transactions only
            </Button>
            <Button
              onClick={onGenerateCSVReport}
              // @ts-ignore
              variant="secondary"
            >
              Generate CSV Report
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
