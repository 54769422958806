import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const GridDownloadExcelPdfButtons = ({
  downloadExcel,
  downloadPDF,
  displayDownloadPDF,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className="print-hide"
        style={{ fontWeight: 700 }}
        onClick={handleMenuClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <DownloadIcon style={{ marginRight: "8px", color: "white", width: 16 }} /> Download
      </Button>
      <Menu
        id="split-button-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            downloadExcel();
          }}
        >
          To Excel
        </MenuItem>
        {displayDownloadPDF && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              downloadPDF();
            }}
          >
            PDF
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default GridDownloadExcelPdfButtons;
