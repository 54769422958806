import React from "react";
import DataGrid, {
  Column,
  Editing,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/cjs/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Box, Button } from "@mui/material";
import { TaxCode } from "../../tax-codes/api/list-of-tax-codes";
import { Site } from "../../../accounting/api/list-of-sites";
import { Contact } from "../../../accounting/api/list-of-contacts";
import { useTheme } from "../../../../context/ThemeContext";
import { useCreateBankJournalTransactions } from "../api/process-transactions-create-bank-journal-transactions";
import { enqueueSnackbar } from "notistack";
import { Account } from "../../../../types/api/accounts";
import { VirtualizedAutocomplete } from "../../../../components/ui/autocomlete/virtualized-autocomplete";

export interface EntryRow {
  approved?: boolean;
  accNo?: string | number;
  taxId?: number;
  privPercentage?: number;
  memo: string;
  site: string;
  review: boolean;
  contact: string;
}

interface UnprocessedTransactionsTableRowEntryProps {
  isLoading: boolean;
  initialData: EntryRow[];
  listOfTaxCodes: TaxCode[];
  listOfSites: Site[];
  listOfContacts: Contact[];
  listOfAccounts: Account[];
  onClose: () => void;
  onSaveAndPostEntry: (args: any) => Promise<void>;
  hideMemo?: boolean;
  hideReview?: boolean;
}

export const UnprocessedTransactionsTableRowEntry = ({
  isLoading = false,
  initialData = [],
  listOfTaxCodes = [],
  listOfSites = [],
  listOfContacts = [],
  listOfAccounts = [],
  onClose,
  onSaveAndPostEntry,
  hideMemo = false,
  hideReview = false,
}: UnprocessedTransactionsTableRowEntryProps) => {
  // const { isDarkMode } = useTheme();

  const dataGridRef = React.useRef<DataGrid>(null);
  const [gridData, setGridData] = React.useState<EntryRow[]>(initialData);

  // Data Source for the Entry Grid
  const dataSource = new CustomStore({
    load: () => Promise.resolve(gridData),
    update: (key: string, values: Partial<EntryRow>) => {
      setGridData((prevData) =>
        prevData.map((item) =>
          item.accNo === key ? { ...item, ...values } : item,
        ),
      );
      return Promise.resolve();
    },
  });

  const getTaxCodeDisplayValue = (rowData: EntryRow) => {
    const taxCode = listOfTaxCodes?.find(
      (taxCode) => taxCode.txcID === rowData.taxId,
    );
    return taxCode ? taxCode.txcCode : "";
  };

  const saveAndPostEntry = async () => {
    const firstRow = dataGridRef.current?.instance.getVisibleRows()[0]?.data;

    if (!firstRow) {
      enqueueSnackbar("No data available to save", { variant: "error" });
      return;
    }

    await onSaveAndPostEntry({
      AccountNo: Number(firstRow.accNo),
      TaxCodeId: firstRow.taxId,
      PrivPc: Number(firstRow.privPercentage ?? 0),
      Description: firstRow.memo,
      SiteId: firstRow.site,
      ReviewYN: firstRow.review ? "Y" : "N",
      ContactId: firstRow.contact,
    });

    onClose();
  };

  const renderContactEditor = (cellInfo) => {
    const column = cellInfo.column;
    const options = column.lookup.dataSource;

    return (
      <VirtualizedAutocomplete
        options={options}
        getOptionLabel={(option) => `${option.cntCode} - ${option.cntName}`}
        onChange={(event, value) => {
          console.log("Selected value:", value);
          cellInfo.setValue(value ? value.cntId : null);
        }}
        value={
          cellInfo.value
            ? options.find((option) => option.cntId === cellInfo.value)
            : null
        }
      />
    );
  };

  return (
    <Box p={2}>
      <DataGrid
        ref={dataGridRef}
        dataSource={dataSource}
        showColumnLines={true}
        loadPanel={{ enabled: false, showIndicator: false, showPane: false }}
        // refreshMode="repaint"
        repaintChangesOnly={true}
        // onCellPrepared={handleCellPrepared}
        // onEditorPreparing={onEditorPreparing}
        // onEditorPrepared={onEditorPrepared}
        showBorders={true}
        wordWrapEnabled={true}
        columnHidingEnabled={false}
        allowColumnReordering={false}
        hoverStateEnabled={true}
        cacheEnabled={true}
        errorRowEnabled={true}
      >
        <Scrolling showScrollbar="always" />
        <Selection mode="single" />
        <Editing
          refreshMode="reshape"
          mode="cell"
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={false}
          confirmDelete={false}
          selectTextOnEditStart={true}
          startEditAction="click"
        />
        <Column
          alignment="left"
          width={150}
          dataField="accNo"
          caption="Account"
          lookup={{
            dataSource: listOfAccounts,
            valueExpr: "accNo",
            displayExpr: "accName",
            searchExpr: ["accNo", "accName"],
          }}
          editorOptions={{
            dropDownOptions: {
              width: 300,
            },
            searchTimeout: 0,
            itemTemplate: (data) =>
              `<div
                style="white-space: normal; display: flex; flex-direction: column;">
                <div>${data.accNo}</div>
                <div>${data.accName}</div>
              </div>`,
          }}
        />
        <Column
          alignment="left"
          dataField="taxId"
          caption="Tax"
          width={150}
          calculateDisplayValue={getTaxCodeDisplayValue}
          lookup={{
            dataSource: listOfTaxCodes,
            valueExpr: "txcID",
            displayExpr: "txcCode",
            searchExpr: ["txcCode", "txcID"],
          }}
          editorOptions={{
            searchExpr: ["txcCode", "txcName"],
            dropDownOptions: {
              width: 300,
            },
            searchTimeout: 0,
            itemTemplate: (data) => `
            <div style="white-space: normal; display: flex; flex-direction: column;">
              <div><b>${data.txcCode}</b></div>
              <div>${data.txcName}</div>
              <div><i>${(data.txcRate * 100).toFixed(2)}%</i></div>
            </div>
          `,
          }}
        />
        <Column
          alignment="right"
          dataField="privPercentage"
          caption="Priv %"
          width={80}
          dataType="number"
          format={{ type: "fixedPoint", precision: 2.0 }}
          editorOptions={{
            min: 0,
            max: 99,
          }}
          validationRules={[
            {
              type: "range",
              min: 0,
              max: 99,
              message: "Priv % must be between 0 and 99",
            },
          ]}
        />
        <Column
          dataField="memo"
          minWidth={undefined}
          alignment="left"
          caption="Memo"
          dataType="string"
          visible={!hideMemo}
        />

        <Column
          dataField="site"
          alignment="left"
          caption="Site"
          width={150}
          lookup={{
            dataSource: listOfSites,
            valueExpr: "dpsID",
            displayExpr: "dpsCode",
          }}
          editorOptions={{
            searchExpr: ["dpsCode", "dpsName"],
            dropDownOptions: {
              width: 300,
            },
            searchTimeout: 0,
            itemTemplate: (data) =>
              `<div
                style="white-space: normal; display: flex; flex-direction: column;">
                <div>${data.dpsCode} - ${data.dpsName}</div>
              </div>`,
          }}
        />
        <Column
          dataField="review"
          caption="Review"
          width={85}
          dataType="boolean"
          visible={!hideReview}
          calculateCellValue={(rowData) => Boolean(rowData.review)}
        />
        <Column
          dataField="contact"
          alignment="left"
          caption="Contact"
          width={150}
          lookup={{
            dataSource: listOfContacts,
            valueExpr: "cntId",
            displayExpr: (item) => `${item.cntName}`,
          }}
          editCellRender={renderContactEditor}
          calculateCellValue={(rowData) => rowData.contact}
        />
      </DataGrid>
      <Box display={"flex"} gap={2} mt={4} mb={4} ml={10}>
        <Button
          // @ts-ignore
          variant="contained"
          onClick={saveAndPostEntry}
          sx={{ fontWeight: 700 }}
          disabled={isLoading}
        >
          {isLoading ? "Saving and posting..." : "Save and Post"}
        </Button>
        <Button
          // @ts-ignore
          variant="secondary"
          onClick={onClose}
          sx={{ fontWeight: 700 }}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
