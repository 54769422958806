// TparBatchManager.jsx
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataGrid, Column, Selection } from "devextreme-react/data-grid";
import {
  Button,
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Modal,
  Select,
  MenuItem,
  FormControl,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver-es";
import GridDownloadExcelPdfButtons from "../../components/GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";
import { downloadPDF } from "../../utils/reusable";

const TparBatchManager = () => {
  const { dbId, lang, BatchID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { apiCall } = useApi();

  const [batchData, setBatchData] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // State for Add Record Modal
  const [openAddModal, setOpenAddModal] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");

  // State for Lodge Modal
  const [openLodgeModal, setOpenLodgeModal] = useState(false);
  const [lodgementID, setLodgementID] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("D");
  const [notes, setNotes] = useState("");
  const [reference, setReference] = useState("");
  const [lodgeAs, setLodgeAs] = useState("Business"); // Default to 'Business'

  // Reference to the DataGrid instance (optional for advanced exports)
  const dataGridRef = useRef(null);

  useEffect(() => {
    fetchBatchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BatchID]);

  useEffect(() => {
    if (batchData) {
      if (filterStatus === "All") {
        setGridData(batchData.GridPack.DataDT);
      } else {
        setGridData(
          batchData.GridPack.DataDT.filter(
            (item) => item.atdStatus === filterStatus,
          ),
        );
      }
    }
  }, [filterStatus, batchData]);

  const handleSaveNotes = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-update-batch-notes?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: { BatchID: BatchID, Note: notes },
      onSuccess: (data) => {
        setLoading(false);
        enqueueSnackbar("Notes saved successfully", { variant: "success" });
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to save notes", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const fetchBatchData = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-batch-records-list?BaseHostURL=${envConfig.mainServiceUrl}&BatchID=${BatchID}`,
      method: "GET",
      onSuccess: (data) => {
        setBatchData(data);
        setGridData(data.GridPack.DataDT);
        setNotes(data.Notes || "");
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Error fetching batch data", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleLodgeWithAto = () => {
    // Ensure batchData is loaded
    if (!batchData || !batchData.GridPack || !batchData.GridPack.DataDT) {
      enqueueSnackbar("Batch data is not available.", {
        variant: "error",
      });
      return;
    }

    // Check if there are any Draft records
    const hasDraftRecords = batchData.GridPack.DataDT.some(
      (item) => item.atdStatus === "D",
    );

    if (hasDraftRecords) {
      // Show a persistent red toast message
      enqueueSnackbar(
        "You cannot lodge report if there are Draft records. Please reassign Drafts to either Confirmed or Do not lodge.",
        {
          variant: "error",
          persist: true,
        },
      );
      return;
    }

    setOpenLodgeModal(true);
  };

  const handleDeleteBatch = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-delete-batch?BatchID=${BatchID}&BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      onSuccess: () => {
        enqueueSnackbar("Batch deleted successfully", { variant: "success" });
        navigate(`/${dbId}/${lang}/books/tpar-batches`);
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to delete batch", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleAddRecord = async () => {
    // Open the Add Record Modal
    setOpenAddModal(true);
    // Fetch contacts to display in the dropdown
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-contacts-list-to-add?BatchID=${BatchID}&BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "GET",
      onSuccess: (data) => {
        if (data && data.Contacts) {
          setContactsList(data.Contacts);
        } else {
          enqueueSnackbar("No contacts available to add", { variant: "info" });
          setOpenAddModal(false);
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to fetch contacts", {
          variant: "error",
        });
        setOpenAddModal(false);
      },
    });
  };

  const handleConfirmAddRecord = async () => {
    if (!selectedContact) {
      enqueueSnackbar("Please select a contact", { variant: "warning" });
      return;
    }

    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-add-contact-record-to-batch?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: { BatchID: BatchID, CntID: selectedContact },
      onSuccess: (data) => {
        enqueueSnackbar("Record added successfully", { variant: "success" });
        const newRecordID = data.AtdID;
        navigate(`/${dbId}/${lang}/books/tpar-record/${newRecordID}`);
        setOpenAddModal(false);
        setSelectedContact("");
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to add record", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleCancelAddRecord = () => {
    setOpenAddModal(false);
    setSelectedContact("");
  };

  const handleChangeRecordStatus = async (newStatus) => {
    if (selectedRows.length === 0) return;

    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-update-status-of-records?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        BatchID,
        RecordIDs: selectedRows,
        Status: newStatus,
      },
      onSuccess: () => {
        enqueueSnackbar("Record statuses updated successfully", {
          variant: "success",
        });
        fetchBatchData();
        setSelectedRows([]);
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to update record statuses", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleMarkAsLodged = async () => {
    if (!lodgementID) {
      enqueueSnackbar("Add Lodgement Info", { variant: "warning" });
      return;
    }

    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-mark-batch-as-lodged-on-portal?BaseHostURL=${envConfig.mainServiceUrl}&BatchID=${BatchID}&LodgementInfo=${lodgementID}`,
      method: "GET",
      onSuccess: () => {
        enqueueSnackbar("Batch marked as lodged", { variant: "success" });
        fetchBatchData();
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to mark batch as lodged", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleReverseToDraft = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-mark-batch-as-not-lodged?BaseHostURL=${envConfig.mainServiceUrl}&BatchID=${BatchID}`,
      method: "GET",
      onSuccess: () => {
        enqueueSnackbar("Batch reverted to draft successfully", {
          variant: "success",
        });
        fetchBatchData();
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to revert batch", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleDownloadSBR = async () => {
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-lodge-batch-via-sbr?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        BatchID,
        FileRef: reference,
        SendAsTaxAgentYN: lodgeAs === "Agent" ? "Y" : "N",
      },
      onSuccess: (data) => {
        let fileName = data.FileName || "lodgement_file";

        // Ensure the filename ends with '.txt'
        if (!fileName.toLowerCase().endsWith(".txt")) {
          fileName += ".txt";
        }

        // Create a Blob with the file content and correct MIME type
        const blob = new Blob([data.FileString], {
          type: "text/plain;charset=utf-8",
        });

        // Trigger download using FileSaver
        saveAs(blob, fileName);

        enqueueSnackbar("Lodgement file downloaded successfully", {
          variant: "success",
        });
        setOpenLodgeModal(false);
        fetchBatchData();
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to lodge", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleProduceLodgementFile = async () => {
    // Include validation if necessary
    if (!reference) {
      enqueueSnackbar("Please enter a reference", { variant: "warning" });
      return;
    }

    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-batch-text-lodgement-file?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      body: {
        BatchID,
        FileRef: reference,
        SendAsTaxAgentYN: lodgeAs === "Agent" ? "Y" : "N",
      },
      onSuccess: (data) => {
        let fileName = data.FileName || "lodgement_file";

        // Ensure the filename ends with '.txt'
        if (!fileName.toLowerCase().endsWith(".txt")) {
          fileName += ".txt";
        }

        // Create a Blob with the file content and correct MIME type
        const blob = new Blob([data.FileString], {
          type: "text/plain;charset=utf-8",
        });

        // Trigger download using FileSaver
        saveAs(blob, fileName);

        enqueueSnackbar("Lodgement file downloaded successfully", {
          variant: "success",
        });
        setOpenLodgeModal(false);
        fetchBatchData();
        setLoading(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to produce lodgement file", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleUpdateSelectedRecords = () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar("No records selected", { variant: "warning" });
      return;
    }
    handleChangeRecordStatus(selectedStatus);
  };

  const onRowClick = (e) => {
    // Prevent navigation when clicking on the checkbox
    if (e.event.target.closest(".dx-select-checkbox")) {
      return;
    }
    const clickedRecord = e.data;
    navigate(`/${dbId}/${lang}/books/tpar-record/${clickedRecord.atdID}`);
  };

  // CSV Export Function using ExcelJS
  const downloadExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Batch Data");

      // Define columns
      worksheet.columns = [
        { header: "Contact", key: "ContactName", width: 30 },
        { header: "Gross", key: "atdGross", width: 15 },
        { header: "GST", key: "atdGST", width: 15 },
        { header: "Tax W", key: "atdTaxW", width: 15 },
        { header: "Amd", key: "atdAmendmentYN", width: 10 },
        { header: "Status", key: "atdStatus", width: 25 },
      ];

      // Add rows
      gridData.forEach((item) => {
        worksheet.addRow({
          ContactName: item.ContactName,
          atdGross: item.atdGross,
          atdGST: item.atdGST,
          atdTaxW: item.atdTaxW,
          atdAmendmentYN: item.atdAmendmentYN === "Y" ? "Yes" : "No",
          atdStatus:
            item.atdStatus === "D"
              ? "Draft"
              : item.atdStatus === "C"
                ? "Confirmed / Ready to Lodge"
                : "Not to lodge",
        });
      });

      // Generate buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Save Excel file using FileSaver
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `tpar-batch-${BatchID}.xlsx`,
      );

      enqueueSnackbar("Excel file downloaded successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      enqueueSnackbar("Failed to export Excel file", { variant: "error" });
    }
  };

  // PDF Export Function via API
  const downloadSummaryPDF = async () => {
    setLoading(true);
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/accounting/tpar-get-batch-summary-pdf-base64?BaseHostURL=${envConfig.mainServiceUrl}&BatchID=${BatchID}`,
      method: "GET",
      onSuccess: async (result) => {
        if (result?.ReportBase64) {
          const isPDFDownloaded = await downloadPDF(
            result?.ReportBase64,
            result?.FileName,
          );
          if (isPDFDownloaded) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage || "Failed to download PDF", {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  return (
    <Box p={3}>
      {loading && <BackdropLoading open={loading} />}
      {batchData && (
        <>
          <Typography variant="h4" gutterBottom>
            {batchData.GridPack.RptTitle}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {batchData.GridPack.RptSubTitle}
          </Typography>

          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              {batchData.WarningsListID > 0 && batchData.Status !== "D" && (
                <Button
                  sx={{ mb: 2 }}
                  variant="outlined"
                  color="warning"
                  onClick={() => {
                    navigate(
                      `/${dbId}/${lang}/reports/warnings-list?p0=${batchData.WarningsListID}`,
                    );
                  }}
                >
                  See Warnings
                </Button>
              )}
              {batchData.Status === "D" && (
                <>
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLodgeWithAto}
                    >
                      Lodge with ATO
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete this Batch
                    </Button>
                    {batchData.WarningsListID > 0 && (
                      <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                          navigate(
                            `/${dbId}/${lang}/reports/warnings-list?p0=${batchData.WarningsListID}`,
                          );
                        }}
                      >
                        See Warnings
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddRecord}
                    >
                      Add Record
                    </Button>
                  </Box>
                  <RadioGroup
                    row
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <FormControlLabel
                      value="All"
                      control={<Radio />}
                      label="Show All"
                    />
                    <FormControlLabel
                      value="D"
                      control={<Radio />}
                      label="Drafts"
                    />
                    <FormControlLabel
                      value="C"
                      control={<Radio />}
                      label="Ready to lodge"
                    />
                    <FormControlLabel
                      value="N"
                      control={<Radio />}
                      label="Do not lodge"
                    />
                  </RadioGroup>
                </>
              )}

              {batchData.Status === "T" && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Batch was exported for lodgement via ATO portal.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <TextField
                      label="Lodgement Info"
                      value={lodgementID}
                      onChange={(e) =>
                        setLodgementID(
                          e.target.value.replace(/[^a-zA-Z0-9]/g, ""),
                        )
                      }
                      helperText="Enter the lodgement reference ID received from the ATO portal."
                      sx={{ flexGrow: 1 }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleMarkAsLodged}
                        fullWidth
                      >
                        Mark as Lodged
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleReverseToDraft}
                        fullWidth
                      >
                        Reverse to Draft
                      </Button>
                    </Box>
                  </Box>
                </>
              )}

              {batchData.Status !== "D" && batchData.Status !== "T" && (
                <>
                  <Typography>{batchData.LodgementReport}</Typography>
                </>
              )}
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                rows={6}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Update Status Section */}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Left Side: Update Status Controls */}
            <Box
              sx={{ display: "flex", alignItems: "flex-start", gap: 2, mb: 2 }}
            >
              <Typography>Update status of selected records to:</Typography>
              <Select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                variant="outlined"
                size="small"
              >
                <MenuItem value="N">Do Not Lodge</MenuItem>
                <MenuItem value="D">Draft</MenuItem>
                <MenuItem value="C">Confirmed and Ready to Lodge</MenuItem>
              </Select>
              <Button variant="contained" onClick={handleUpdateSelectedRecords}>
                Update
              </Button>
            </Box>

            {/* Right Side: Save Notes Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveNotes}
            >
              Save Notes
            </Button>
          </Box>

          {/* Integrate Export Buttons */}
          {gridData?.length > 0 && (
            <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
              <GridDownloadExcelPdfButtons
                downloadExcel={downloadExcel}
                downloadPDF={downloadSummaryPDF}
                displayDownloadPDF={true}
              />
            </Box>
          )}
          {/* Data Grid */}
          <DataGrid
            ref={dataGridRef}
            dataSource={gridData}
            keyExpr="atdID"
            showBorders={true}
            onSelectionChanged={(e) => setSelectedRows(e.selectedRowKeys)}
            selectedRowKeys={selectedRows}
            onRowClick={onRowClick}
            rowAlternationEnabled={true}
            // Add any additional DataGrid props here
          >
            <Selection mode="multiple" />
            {/* Removed the <Export> component */}
            <Column
              dataField="ContactName"
              caption="Contact"
              dataType="string"
              allowEditing={false}
            />
            <Column
              dataField="atdGross"
              caption="Gross"
              dataType="number"
              format={{ type: "currency", precision: 2 }}
              alignment="right"
              allowEditing={false}
            />
            <Column
              dataField="atdGST"
              caption="GST"
              dataType="number"
              format={{ type: "currency", precision: 2 }}
              alignment="right"
              allowEditing={false}
            />
            <Column
              dataField="atdTaxW"
              caption="Tax W"
              dataType="number"
              format={{ type: "currency", precision: 2 }}
              alignment="right"
              allowEditing={false}
            />
            <Column
              dataField="atdAmendmentYN"
              caption="Amd"
              dataType="string"
              allowEditing={false}
              calculateDisplayValue={(rowData) =>
                rowData.atdAmendmentYN === "Y" ? "Yes" : "No"
              }
            />
            <Column
              dataField="atdStatus"
              caption="Status"
              dataType="string"
              calculateDisplayValue={(rowData) =>
                rowData.atdStatus === "D"
                  ? "Draft"
                  : rowData.atdStatus === "C"
                    ? "Confirmed / Ready to Lodge"
                    : "Not to lodge"
              }
              allowEditing={false}
            />
          </DataGrid>

          {/* Add Record Modal */}
          <Modal open={openAddModal} onClose={handleCancelAddRecord}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h5" gutterBottom>
                Select contact to add
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select
                  value={selectedContact}
                  onChange={(e) => setSelectedContact(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select a contact
                  </MenuItem>
                  {contactsList.map((contact) => (
                    <MenuItem key={contact.CntID} value={contact.CntID}>
                      {contact.CntName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={handleConfirmAddRecord}
                  sx={{ mr: 2 }}
                >
                  OK
                </Button>
                <Button variant="outlined" onClick={handleCancelAddRecord}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>

          {/* Lodge Modal */}
          <Modal open={openLodgeModal} onClose={() => setOpenLodgeModal(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h5" gutterBottom mb={2}>
                Lodge TPAR
              </Typography>
              <Typography variant="h5" fontSize={18} gutterBottom mb={2}>
                Lodge via SBR
              </Typography>
              <Typography fontSize={14} mb={2}>
                Clicking the below button will lodge this batch of TPAR reports
                with the ATO. Confirmation will be added to this batch when a
                response is received.
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  onClick={handleDownloadSBR}
                  sx={{ mr: 2 }}
                  disabled={loading}
                >
                  Lodge to ATO
                </Button>
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="h5" fontSize={18} gutterBottom mb={2}>
                Download file to lodge via ATO portal
              </Typography>
              <TextField
                label="Your reference"
                value={reference}
                onChange={(e) => setReference(e.target.value.slice(0, 10))}
                inputProps={{ maxLength: 10 }}
                fullWidth
                sx={{ mb: 2 }}
              />
              <FormControl component="fieldset" sx={{ mb: 2 }}>
                <RadioGroup
                  value={lodgeAs}
                  onChange={(e) => setLodgeAs(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Business"
                    control={<Radio />}
                    label="Lodge as Business"
                  />
                  <FormControlLabel
                    value="Agent"
                    control={<Radio />}
                    label="Lodge as Agent"
                  />
                </RadioGroup>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={handleProduceLodgementFile}
                  sx={{ mr: 2 }}
                  disabled={!reference || loading}
                >
                  Get file
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setOpenLodgeModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}

      {/* Delete Confirmation Modal */}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Delete this batch?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setOpenDeleteModal(false);
                handleDeleteBatch();
              }}
              sx={{ mr: 2 }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenDeleteModal(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TparBatchManager;
