import React, { memo, useCallback, useRef, useState } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import DataGrid, {
  Column,
  DataGridTypes,
  Grouping,
  Item,
  Scrolling,
  SearchPanel,
  Sorting,
  Toolbar,
} from "devextreme-react/cjs/data-grid";

import { useTheme } from "../../../../context/ThemeContext";
import {
  GeneralLedgerDownloadAsExcelDialog,
  GeneralLedgerDownloadAsPDFDialog,
} from "./general-ledger-download-data";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { formatDate } from "../../../../utils/format-date";
import {
  GeneralLedgerColumnsList,
  GeneralLedgerDataRow,
} from "../../../../types/api/general-ledger";
import {
  ContextMenuPreparingEvent,
  RowClickEvent,
} from "devextreme/ui/data_grid";
import { getCookie } from "../../../../utils/api";
import { useGetIP } from "../../../../hooks/get-ip";
import { DownloadButton } from "../../../../components/ui/buttons/download-button";
import { RefreshButton } from "../../../../components/ui/buttons/refresh-button";
import { YNToBool } from "../../../../utils/format-bool";
import { getAlignment } from "../../../../helpers/table";
import { openTransactionInURL } from "../helpers/get-transaction-url";

import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { saveAs } from "file-saver-es";
import useApi from "../../../../hooks/useApi";
import { decodeHTMLEntities } from "../../../../utils/format-text";
import { PDFButton } from "../../../../components/ui/buttons/pdf-button";
import { FullScreenButton } from "../../../../components/ui/buttons/fullscreen-button";
import { openInNewTabFromPartialUrl } from "../../../../utils/open-in-new-tab";
import { DataGridWrapper } from "../../../../components/ui/table/data-grid-wrapper";
import { ClearIcon } from "@mui/x-date-pickers-pro";
import { GridSearchIcon } from "@mui/x-data-grid-pro";
import { SearchInput } from "../../../../components/ui/inputs/search-input";
import { GeneralLedgerDataGridToolbar } from "./general-ledger-data-grid-toolbar";
import { useParams } from "react-router-dom";

LicenseInfo.setLicenseKey(
  "d4fd116dcdd62ee808f5368ad91259faTz03ODUxNCxFPTE3MzEyNzMxNTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

const renderGeneralTableCell = (
  cellInfo: DataGridTypes.ColumnCellTemplateData,
  currentColumn: GeneralLedgerColumnsList,
) => {
  const rowType = cellInfo.data?.RowType;
  const hideZero = currentColumn.HideZeroOnAmountColumnYN === "Y";
  const displayZerosOnClosingBalance =
    rowType === "CB" &&
    (cellInfo.column.dataField === "Balance" ||
      cellInfo.column.dataField === "Credit" ||
      cellInfo.column.dataField === "Debit");

  if (cellInfo.column.dataField === "TrDate") {
    return formatDate(cellInfo.value);
  }

  if (
    rowType === "CB" ||
    rowType === "OB" ||
    rowType === "H" ||
    rowType === "F"
  ) {
    return (
      <Box sx={{ fontWeight: "bold" }}>
        {formatFinancialNumber(cellInfo.value, {
          defaultValue: displayZerosOnClosingBalance ? "0.00" : undefined,
          rounding: currentColumn.RpcRounding,
          hideZero: !displayZerosOnClosingBalance,
        })}
      </Box>
    );
  }
  if (
    cellInfo.column.dataField === "TaxAmt" ||
    cellInfo.column.dataField === "OriginalAmt" ||
    cellInfo.column.dataField === "PrivAmt" ||
    cellInfo.column.dataField === "Debit" ||
    cellInfo.column.dataField === "Credit" ||
    cellInfo.column.dataField === "Balance"
  ) {
    return formatFinancialNumber(cellInfo.value, {
      defaultValue: hideZero ? undefined : "0.00",
      rounding: currentColumn.RpcRounding,
      hideZero: true,
    });
  }

  if (cellInfo.column.dataField === "Description") {
    return decodeHTMLEntities(cellInfo.value);
  }

  return cellInfo.value;
};

interface Props {
  data: GeneralLedgerDataRow[] | undefined;
  columns: GeneralLedgerColumnsList[] | undefined;
  startDate: string;
  endDate: string;
  onRefresh: () => void;
  listOfAccountsNameByAccNo: Record<string, string> | undefined;
}

export const GeneralLedgerTable = ({
  data,
  columns,
  startDate,
  endDate,
  onRefresh,
  listOfAccountsNameByAccNo,
}: Props) => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const ip = useGetIP();
  const dataGridRef = useRef<any>();
  const { dbId } = useParams();

  const [isPDFDialogOpen, setIsPDFDialogOpen] = useState(false);
  // const [isExcelDialogOpen, setIsExcelDialogOpen] = useState(false);
  const [collapseAll, setCollapseAll] = useState(true);
  const [openFullscreen, setOpenFullscreen] = useState(false);

  const handleContextMenuPreparing = (
    e: ContextMenuPreparingEvent<GeneralLedgerDataRow, any>,
  ) => {
    if (e.row && e.row.rowType === "data") {
      const { RowType, transID } = e.row.data;
      if (["CB", "OB", "H", "F"].includes(RowType)) return;

      e.items = e.items || [];
      e.items.push({
        text: "Open in new tab",
        onItemClick: () =>
          openTransactionInURL(
            transID,
            RowType,
            dbId,
            ip,
            apiCall,
            e.row?.data.LinkToDets,
          ),
      });
    }
  };

  const handleRowClick = (e: RowClickEvent<GeneralLedgerDataRow, any>) => {
    if (e.rowType === "group") {
      if (e.isExpanded) {
        e.component?.collapseRow(e.key);
      } else {
        e.component?.expandRow(e.key);
      }
    }

    if (e.rowType === "data") {
      const { RowType, transID } = e.data;
      if (["CB", "OB", "H", "F"].includes(RowType)) return;

      if (e.data.LinkToDets) {
        openInNewTabFromPartialUrl(e.data.LinkToDets, apiCall, dbId);
      }
    }
  };

  const handleOnExporting = async () => {
    try {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      const rowsToDelete = new Set();

      await exportDataGrid({
        component: dataGridRef?.current?.instance,
        worksheet,
        customizeCell(options) {
          if (options.gridCell?.rowType === "group") {
            rowsToDelete.add(options.excelCell.row);
          }

          if (
            options.gridCell?.rowType === "data" &&
            options.gridCell?.data?.TrDate &&
            options.gridCell?.column?.dataField === "TrDate"
          ) {
            options.excelCell.value =
              options.gridCell?.data?.TrDate.split("T")[0];
          }
        },
      });

      const rowsToDeleteArray = Array.from(rowsToDelete).sort(
        (a: number, b: number) => b - a,
      );

      rowsToDeleteArray.forEach((rowNumber: number) => {
        worksheet.spliceRows(rowNumber, 1);
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `GL ${startDate} to ${endDate}.xlsx`,
      );
    } catch (e) {
      console.error("Error exporting General Ledger Reports to Excel: ", e);
    }
  };

  const getColumnWidth = useCallback((column: GeneralLedgerColumnsList) => {
    if (column.RpcHeader === "Description") return undefined;
    return column.RpcMaxWidth;
  }, []);

  const getAccountNameByAccNo = (accNo: string) => {
    if (!listOfAccountsNameByAccNo) return accNo;
    return `${accNo} ${listOfAccountsNameByAccNo?.[String(accNo)] || ""}`;
  };

  if (!data)
    return (
      <Stack
        py={2}
        alignItems="center"
        justifyContent="center"
        boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
        marginTop={3}
        height={"calc(100vh - 300px)"}
        paddingTop={2}
        sx={{
          transition: "all 1s ease",
        }}
      >
        <span>Apply filters to view data</span>
      </Stack>
    );

  return (
    // TODO: Extract this to a separate component as a container
    <DataGridWrapper isFullScreen={openFullscreen}>
      <GeneralLedgerDownloadAsPDFDialog
        isOpen={isPDFDialogOpen}
        onClose={() => setIsPDFDialogOpen(false)}
      />

      <GeneralLedgerDataGridToolbar
        isCollapsed={collapseAll}
        onCollapse={() => setCollapseAll(!collapseAll)}
        onRefresh={onRefresh}
        onExcel={handleOnExporting}
        onPDF={() => setIsPDFDialogOpen(true)}
        onSearchChange={(e) =>
          dataGridRef.current?.instance.searchByText(e.target.value)
        }
        onFullScreen={() => setOpenFullscreen(!openFullscreen)}
      />

      <Box
        sx={{
          ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
            backgroundColor: isDarkMode ? "#232939" : "#FAFAFA",
          },
        }}
      >
        <DataGrid
          ref={dataGridRef}
          dataSource={data}
          showBorders={false}
          rowAlternationEnabled={true}
          style={{
            border: "none",
            borderRadius: "8px",
            marginTop: "10px",
            marginBottom: "100px",
          }}
          scrolling={{ mode: "virtual" }}
          onRowClick={handleRowClick}
          onContextMenuPreparing={handleContextMenuPreparing}
          hoverStateEnabled={true}
          wordWrapEnabled={true}
          width={"100%"}
        >
          <SearchPanel visible={false} highlightCaseSensitive={true} />
          <Grouping allowCollapsing autoExpandAll={collapseAll} />
          <Sorting mode="multiple" />

          {columns?.length &&
            columns.map((column, index) => (
              <Column
                key={index}
                dataField={column.RpcDataSource}
                caption={column.RpcHeader}
                allowResizing={YNToBool(column.RpcAllowResizing)}
                minWidth={column.RpcMinWidth}
                allowFiltering={YNToBool(column.RpcFilter)}
                alignment={getAlignment(column.RpcAlign)}
                allowSearch={YNToBool(column.RpcSearchable)}
                allowSorting={YNToBool(column.RpcSort)}
                groupIndex={column.RpcHeader === "accNo" ? 0 : undefined}
                groupCellRender={(cellInfo) =>
                  getAccountNameByAccNo(cellInfo.value)
                }
                cellRender={(cellInfo) =>
                  renderGeneralTableCell(cellInfo, column)
                }
                visible={YNToBool(column.RpcVisible)}
                fixed={YNToBool(column.RpcFreeze)}
                width={getColumnWidth(column)}
              />
            ))}
        </DataGrid>
      </Box>
    </DataGridWrapper>
  );
};
