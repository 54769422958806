import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";

import { useTheme } from "../../../../context/ThemeContext";
import {
  commonThemes,
  darkTheme,
  lightTheme,
} from "../../../../utils/themes/themes";
import { formatDateToISO } from "../../../../utils/format-date";
import { boolToYN } from "../../../../utils/format-bool";
import { useDownloadPDFGeneralLegderReport } from "../api/get-reports-pdf";
import { useGeneralLedgerStoreBase, useLedgedStore } from "../store";
import { enqueueSnackbar } from "notistack";
import { useLoginPopup } from "../../../../context/LoginPopupContext";

interface CommonGeneralLedgerDownloadAsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const GeneralLedgerDownloadAsPDFDialog = ({
  isOpen,
  onClose,
}: CommonGeneralLedgerDownloadAsProps) => {
  const state = useGeneralLedgerStoreBase((state) => state);
  const { mutate: downloadAsPDF, isPending } =
    useDownloadPDFGeneralLegderReport({
      mutationConfig: {
        onError: (error) => {
          enqueueSnackbar(error?.message, { variant: "error" });
        },
      },
    });

  const { handleOpen } = useLoginPopup();
  // TODO: Turn into a one hook
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const showPrintDetailsYN = state.showFilters.showPrintDetailsYN;
  const setShowFilterByType = useLedgedStore.use.setShowFilterByType();

  return (
    <Dialog sx={{ ">div>div": { p: 4 } }} open={isOpen} onClose={onClose}>
      <DialogTitle
        // @ts-ignore
        sx={{ ...commonThemes.popupTitle, p: 0 }}
      >
        <span>Download to PDF</span>
      </DialogTitle>
      <DialogContent sx={{ p: 0, mt: "30px" }}>
        <FormControlLabel
          key={"showPrintDetailsYN"}
          value={!!showPrintDetailsYN}
          control={
            <Checkbox
              checked={!!showPrintDetailsYN}
              onChange={() =>
                setShowFilterByType("showPrintDetailsYN", !showPrintDetailsYN)
              }
              id="showPrintDetailsYN"
            />
          }
          label={"Show Print Details"}
          // @ts-ignore
          sx={{ mr: 2, span: { ...commonThemes.normalText } }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: "30px" }}>
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(132, 159, 35, 0.04)",
            },
          }}
          onClick={() => {
            setShowFilterByType("showPrintDetailsYN", false);
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          // @ts-ignore
          variant="contained"
          disabled={isPending}
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: theme.palette.primary.main,
            },
          }}
          // not ideal but whatever - keep in "sync" wtih body in GeneralLedger.tsx
          onClick={async () => {
            await downloadAsPDF(
              {
                StartDate: formatDateToISO(state.startDate?.toDate()),
                EndDate: formatDateToISO(state.endDate?.toDate()),
                CashMethodYN: boolToYN(state.showFilters.cashMethod),
                ShowDeptSiteYN: boolToYN(state.showFilters.showDeptSiteYN),
                ShowUserYN: boolToYN(state.showFilters.showUserYN),
                ShowRecDateYN: boolToYN(state.showFilters.showRecDateYN),
                ShowCntCodeYN: boolToYN(state.showFilters.showCntCodeYN),
                ShowIfNoTransactionsYN: boolToYN(
                  state.showFilters.showIfNoTransactionsYN,
                ),
                ReconciliationStatus_GE9: "3",
                RecordStatus_GE11: state.recordStatus,
                ShowPrintDetailsYN: boolToYN(showPrintDetailsYN),

                // TODO: fix these ???
                // AccList: state.selectedAccounts.map((acc) => acc.accNo),
                AccList: state.selectedAccounts,
                DpsList: state.selectedSites,
                CntList: state.selectedContacts,

                // other?
                BuildHeadersAndDividersYN: "Y",
                // UsrList: [],
                // BankFeedsStatus: 0
              },
              handleOpen,
            );
            onClose();
          }}
        >
          Download PDF Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface GeneralLedgerDownloadAsExcelDialogProps
  extends CommonGeneralLedgerDownloadAsProps {
  onExporting: () => Promise<void>;
}

export const GeneralLedgerDownloadAsExcelDialog = ({
  isOpen,
  onExporting,
  onClose,
}: GeneralLedgerDownloadAsExcelDialogProps) => {
  // TODO: Turn into a one hook
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const downloadAsExcel = async () => {
    await onExporting();
    onClose();
  };

  return (
    <Dialog sx={{ ">div>div": { p: 4 } }} open={isOpen} onClose={onClose}>
      <DialogTitle
        // @ts-ignore
        sx={{ ...commonThemes.popupTitle, p: 0 }}
      >
        <span>Download to Excel</span>
      </DialogTitle>
      <DialogActions sx={{ p: 0, mt: "30px" }}>
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(132, 159, 35, 0.04)",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          // @ts-ignore
          variant="contained"
          // disabled={loading || loadingPDF}
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: theme.palette.primary.main,
            },
          }}
          onClick={downloadAsExcel}
        >
          Download Excel Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};
