import {
  Autocomplete,
  Box,
  FormControl,
  ListItem,
  TextField,
} from "@mui/material";
import React from "react";
import { useTheme } from "../../../../context/ThemeContext";
import { commonThemes } from "../../../../utils/themes/themes";

interface ProcessTransactionsAccountSelectionProps {
  accounts: any[];
  selectedAccount: any;
  onAccountChange: (newValue: any) => void;
}

export const ProcessTransactionsAccountSelection = ({
  accounts,
  selectedAccount,
  onAccountChange,
}: ProcessTransactionsAccountSelectionProps) => {
  const { isDarkMode } = useTheme();

  return (
    <FormControl sx={{ flex: 1 }}>
      <Autocomplete
        options={accounts}
        value={selectedAccount}
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            padding: "8.5px 0 8.5px 4px",
            // maxWidth: "400px",
            flex: 1,
          },
        }}
        getOptionLabel={(option) => `${option.Label}`}
        onChange={(event, newValue) => onAccountChange(newValue)}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props}>
            <Box>
              <Box fontWeight={"bold"}>{option.AccNo}</Box>
              <div>{option.Label}</div>
            </Box>
          </ListItem>
        )}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select an Account"
            sx={{
              label: {
                fontSize: 16,
                // @ts-ignore
                ...commonThemes.inputLabel,
                color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};
