import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconCircleExclamation } from "../../../components/icons/circle-exclamation";
import { IconFile, IconMail, IconGear } from "../../../components/icons";
import { IconPencil } from "../../../components/icons/pencil";

interface PayrollEventFinalisedActionsProps {
  onSTP: () => void;
  onSendPayslips: () => void;
  onReopen: () => void;
  onAbaFile: () => void;
  onSeeWarnings: () => void;
  warningsCount: number;
}

export const PayrollEventFinalisedActions: React.FC<
  PayrollEventFinalisedActionsProps
> = ({
  onSTP,
  onSendPayslips,
  onReopen,
  onAbaFile,
  onSeeWarnings,
  warningsCount,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      gap={2}
      mt={6}
      mb={8}
    >
      <Button
        variant="outlined"
        color="secondary"
        onClick={onSTP}
        startIcon={<IconGear color="secondary" palleteType="main" />}
      >
        Single Touch Payroll (STP)
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onSendPayslips}
        startIcon={<IconMail color="secondary" palleteType="main" />}
      >
        Send Payslips
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onReopen}
        startIcon={<IconPencil color="secondary" palleteType="main" />}
      >
        Reopen Pay Event
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={onAbaFile}
        startIcon={<IconFile color="secondary" palleteType="main" />}
      >
        ABA File
      </Button>
      <Button
        variant="outlined"
        color={warningsCount > 0 ? "warning" : "secondary"}
        onClick={onSeeWarnings}
        startIcon={
          <IconCircleExclamation
            color={warningsCount > 0 ? "warning" : "secondary"}
            palleteType="main"
          />
        }
      >
        See Warnings {warningsCount > 0 && `(${warningsCount})`}
      </Button>
    </Box>
  );
};
