import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { List, ListRowProps } from "react-virtualized";

interface ListboxComponentProps {
  children: React.ReactNode;
  role?: string;
  [key: string]: any;
}

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  ListboxComponentProps
>(function ListboxComponent(props, ref) {
  const { children, role, width, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 36;

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={250}
          width={width}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(listRowProps: ListRowProps) => {
            if (!children) {
              return null;
            }
            return React.cloneElement(children[listRowProps.index], {
              style: listRowProps.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

interface VirtualizedAutocompleteProps {
  options: any[];
  getOptionLabel: (option: any) => string;
  onChange: (event: any, value: any) => void;
  value?: any;
  label?: string;
  width?: number;
  otherAutoCompleteProps?: any;
}

export function VirtualizedAutocomplete({
  options,
  getOptionLabel,
  onChange,
  value,
  label,
  width = 300,
  otherAutoCompleteProps = {},
}: VirtualizedAutocompleteProps) {
  return (
    <Autocomplete
      id="virtualize-demo"
      style={{ width: width }}
      disableListWrap
      ListboxComponent={ListboxComponent}
      ListboxProps={{width: width, minWidth: '100%'}}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} fullWidth />
      )}
      {...otherAutoCompleteProps}
    />
  );
}
