import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { DxElement } from "devextreme/core/element";
import {
  UnprocessedTransactions,
  UnprocessedTransactionsSuggestion,
} from "../types";
import { commonThemes } from "../../../../utils/themes/themes";
import { formatDate } from "../../../../utils/format-date";
import { formatFinancialNumber } from "../../../../utils/format-number";
import { UnprocessedTransactionsTableSuggestions } from "./process-transactions-table-row-suggestions";
import {
  EntryRow,
  UnprocessedTransactionsTableRowEntry,
} from "./process-transactions-table-row-entry";
import { useStoreProcessTransactionsDependencies } from "../store/process-transactions-store-deps";
import { useTheme } from "../../../../context/ThemeContext";
import { useTransactions } from "../store/process-transactions-store-transactions";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../../../config";
import { createSessionForExternal } from "../../../../utils/reusable";
import useApi from "../../../../hooks/useApi";
import { useCreateBankJournalTransactions } from "../api/process-transactions-create-bank-journal-transactions";
import { enqueueSnackbar } from "notistack";
import { useGetIP } from "../../../../hooks/get-ip";
import { useProcessTransactionsGridData } from "../api/process-transcations-grid-data";
import { useStoreAccounts } from "../store/process-transactions-store-accounts";
import DisregardTransactionDialog from "./process-transactions-disregard-dialog";
import ProcessTransactionsMatchTransactionsDialog from "./process-transactions-match-transactions";
import {
  IconMemoPencil,
  IconPlus,
  IconTags,
  IconReceiptAlt3,
  IconTrash,
} from "../../../../components/icons";

// interface UnprocessedTransactionsTableRowProps {
//   data: UnprocessedTransactions; // the data object for the current row
//   rowIndex: number; // the index of the row
//   component: any; // the DataGrid component instance
//   isSelected?: boolean; // whether the row is selected
//   isEditing?: boolean; // whether the row is in editing mode
//   rowType: string; // the type of the row (e.g., 'data', 'group', 'header')
//   values: any[]; // an array of the values for the row's cells
//   rowElement: DxElement; // the actual DOM element for the row
//   key: any; // the key of the row
//   groupIndex?: number; // the index of the group (if applicable)

//   selectedRowKeys: string[]; // an array of the keys of the selected rows
//   onSelectionChanged: (e: any) => void;
//   handleClearFilters: () => void;
//   transactions: any[];
// }

interface UnprocessedTransactionsTableRowProps {
  data: UnprocessedTransactions; // the data object for the current row
  selectedRowKeys: String[]; // an array of the keys of the selected rows
  onSelectionChanged: (e: any) => void;
  handleClearFilters: () => void;
  transactions: UnprocessedTransactions[];
}

export const UnprocessedTransactionsTableRow = ({
  data,
  selectedRowKeys = [],
  onSelectionChanged,
  handleClearFilters,
  transactions,
}: UnprocessedTransactionsTableRowProps) => {
  const { isDarkMode } = useTheme();
  const { dbId } = useParams();
  const { apiCall } = useApi();
  const ip = useGetIP();

  const navigate = useNavigate();

  const [showQuickAddMenu, setShowQuickAddMenu] = React.useState(false);
  const [showDisregardDialog, setShowDisregardDialog] = React.useState(false);
  const [showMatchTransactionsDialog, setShowMatchTransactionsDialog] =
    React.useState<boolean>(false);

  const [matchingTransactionsColumns, setMatchingTransactionsColumns] =
    React.useState<any[]>([]);
  const [matchingTransactionsData, setMatchingTransactionsData] =
    React.useState<any[]>([]);

  const [isApplyLoading, setIsApplyLoading] = React.useState(false);
  const [isDisregardLoading, setIsDisregardLoading] = React.useState(false);

  const setTransactions = useTransactions.use.setTransactions();
  const setInitialTransactions = useTransactions.use.setInitialTransactions();

  const createBankJournalTransactions = useCreateBankJournalTransactions({
    onSuccess: () => {
      handleClearFilters();
      enqueueSnackbar("Transactions added successfully", {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(`Error adding transactions: ${error}`, {
        variant: "error",
      });
    },
  });

  const gridDataMutation = useProcessTransactionsGridData({
    // @ts-ignore
    onSuccess: (data) => {
      handleClearFilters();
      if (data?.Transactions) {
        setTransactions(data?.Transactions);
        setInitialTransactions(data?.Transactions);
      } else {
        setTransactions([]);
        setInitialTransactions([]);
      }
    },
    // @ts-ignore
    onError: (error) => {
      enqueueSnackbar(`Error fetching transactions: ${error}`, {
        variant: "error",
      });
    },
  });

  const selectedAccount = useStoreAccounts.use.selectedAccount();

  const appliedRule = useTransactions.use.appliedRule();
  const setAppliedRule = useTransactions.use.setAppliedRule();

  // const transactions = useTransactions.use.transactions();

  const setSelectedRowsKeys = useTransactions.use.setSelectedRowsKeys();
  const setFilteredRowsKeys = useTransactions.use.setFilteredRowsKeys();

  const listOfTaxCodes =
    useStoreProcessTransactionsDependencies.use.listOfTaxCodes();
  const listOfAllSites =
    useStoreProcessTransactionsDependencies.use.listOfSites();
  const listOfContacts =
    useStoreProcessTransactionsDependencies.use.listOfContacts();
  const listOfAccounts =
    useStoreProcessTransactionsDependencies.use.listOfAccounts();

  const handleQuickAddClick = () => setShowQuickAddMenu(!showQuickAddMenu);
  const handleDiregardClose = () => setShowDisregardDialog(false);
  const onDisregard = () => setShowDisregardDialog(true);
  const onCloseMatchTransactionsDialog = () =>
    setShowMatchTransactionsDialog(false);

  const onCloseQuickAddMenu = () => setShowQuickAddMenu(false);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSelectedKeys = event.target.checked
      ? [...selectedRowKeys, data.ID]
      : selectedRowKeys.filter((key) => key !== data.ID);

    onSelectionChanged({ selectedRowKeys: updatedSelectedKeys });
  };

  const handleFindAllWithRule = (
    suggestion: UnprocessedTransactionsSuggestion,
  ) => {
    const matchCode = suggestion.MatchCode;

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionSuggestions = transaction.Suggestions;
      return transactionSuggestions.some(
        (suggestion) => suggestion.MatchCode === matchCode,
      );
    });

    setTransactions(filteredTransactions);
    setSelectedRowsKeys(filteredTransactions.map((t) => t.ID));
    setFilteredRowsKeys(filteredTransactions.map((t) => t.ID));
    setAppliedRule(matchCode);
  };

  const handleApplySuggestion = async (
    suggestion: UnprocessedTransactionsSuggestion,
  ) => {
    // if (!selectedAccount) {
    //   return;
    // }

    setIsApplyLoading(true);

    const response = await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/bank-feeds/insert-feed-journal-using-bank-rule-v2?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      // @ts-ignore
      body: {
        Bnk0105: "",
        BftID: data.ID,
        // OverridePrompts: "Y",
        OverridePromptsYN: "Y",
      },
      onSuccess: async () => {
        enqueueSnackbar("Suggestion applied successfully", {
          variant: "success",
        });
        setIsApplyLoading(false);
        await gridDataMutation.fetchGridData({
          // @ts-ignore
          AccountID: selectedAccount.ID,
          Bnk0114: "",
          OffsetNo: 0,
          ReturnNo: 100,
          RuleFilter: "",
        });
      },
      // @ts-ignore
      onError: async (error) => {
        enqueueSnackbar(`Failed to apply suggestion: ${error}`, {
          variant: "error",
        });
        setIsApplyLoading(false);
      },
    });
  };

  const onNewJournal = () => {
    setInitialTransactions([]);
    navigate(`../${dbId}/en-au/books/bank-journal?feedId=${data.ID}`);
  };

  const onNewPayment = () => {
    setInitialTransactions([]);
    const newUrl = `https://${envConfig.correspondingService}/${dbId}/en-au/payments/${data.Amount < 0 ? "bill" : "invoice"}/0-0-0/${data.ID}`;
    createSessionForExternal(newUrl, ip, true, apiCall, dbId);
  };

  const onMatchExisitingTransactions = async () => {
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/bank-feeds/get-matching-transactions-grid-pack?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      // @ts-ignore
      body: {
        Bnk0110: "",
        AccNo: data.AccNo,
        Amount: data.Amount,
        TrDate: data.TransactionDate,
      },
      // @ts-ignore
      onSuccess: async (response) => {
        console.log("Match existing transactions response: ", response);
        setMatchingTransactionsColumns(response?.ColumnsList || []);
        setMatchingTransactionsData(response?.DataDT || []);
        setShowMatchTransactionsDialog(true);
      },
      // @ts-ignore
      onError: async (error) => {
        enqueueSnackbar(`Error: ${error}`, {
          variant: "error",
        });
        setShowMatchTransactionsDialog(false);
      },
    });
  };

  const onSaveAndPostEntry = async (args) => {
    await createBankJournalTransactions.createBankJournalTransactions({
      ...args,
      ListOfTransactions: [data.ID],
    });

    if (!selectedAccount) {
      return;
    }

    await gridDataMutation.fetchGridData({
      AccountID: selectedAccount.ID,
      Bnk0114: "",
      OffsetNo: 0,
      ReturnNo: 100,
      RuleFilter: "",
    });

    setShowQuickAddMenu(false);
  };

  const initialEntryData: EntryRow[] = [
    {
      accNo: undefined,
      taxId: undefined,
      memo: "",
      contact: "",
      review: false,
      site: "",
    },
  ];

  React.useEffect(() => {
    if (
      createBankJournalTransactions.isError ||
      createBankJournalTransactions.error
    ) {
      enqueueSnackbar(
        `Error adding transactions: ${createBankJournalTransactions.error}`,
        {
          variant: "error",
        },
      );
    }
  }, [createBankJournalTransactions]);

  return (
    // <tr key={data.ID}>
    //   <td style={{ padding: 0, margin: 0 }} colSpan={6}>
    <Box
      width={"100%"}
      border={isDarkMode ? "1px solid #3e424d" : "1px solid #e0e0e0"}
      borderRadius={1}
      marginBottom={1}
    >
      <Box display="flex" alignItems={"top"} gap={4} p={2}>
        <Box display="flex" alignItems={"center"} height={"24px"}>
          <UnprocessedTransactionsTableRowSelector
            value=""
            checked={selectedRowKeys.includes(data.ID)}
            onChange={handleSelectChange}
            label=""
          />
        </Box>
        <Box display="flex" flexDirection={"column"} gap={2} flex="1">
          <Typography fontSize={16} fontWeight={400} component="h4">
            {data.Description}
          </Typography>
          <Box display={"flex"} gap={4}>
            <Typography>{formatDate(data.TransactionDate)}</Typography>
            <UnprocessedTransactionsTableRowAmount amount={data.Amount} />
          </Box>
          <UnprocessedTransactionsTableRowActions
            disableQuickAdd={showQuickAddMenu}
            onQuickAddClick={handleQuickAddClick}
            onNewJournal={onNewJournal}
            onNewPayment={onNewPayment}
            onDisregard={onDisregard}
            onMatchExisitingTransactions={onMatchExisitingTransactions}
          />
          <UnprocessedTransactionsTableSuggestions
            suggestions={data.Suggestions}
            isDarkMode={isDarkMode}
            isApplyLoading={isApplyLoading}
            onApplySuggestion={handleApplySuggestion}
            onFindAllWithRule={handleFindAllWithRule}
          />
        </Box>
      </Box>
      {showQuickAddMenu ? (
        <UnprocessedTransactionsTableRowEntry
          isLoading={createBankJournalTransactions.isLoading}
          initialData={initialEntryData}
          listOfTaxCodes={listOfTaxCodes || []}
          listOfSites={listOfAllSites || []}
          listOfContacts={listOfContacts || []}
          listOfAccounts={listOfAccounts || []}
          onClose={onCloseQuickAddMenu}
          onSaveAndPostEntry={onSaveAndPostEntry}
          hideMemo={false}
        />
      ) : null}
      <DisregardTransactionDialog
        transactionId={data.ID}
        open={showDisregardDialog}
        onClose={handleDiregardClose}
        handleClearFilters={handleClearFilters}
      />
      <ProcessTransactionsMatchTransactionsDialog
        columns={matchingTransactionsColumns}
        data={matchingTransactionsData}
        transactionName={data.Description}
        transactionId={data.ID}
        isOpen={showMatchTransactionsDialog}
        onClose={onCloseMatchTransactionsDialog}
      />
    </Box>
    //   </td>
    // </tr>
  );
};

interface UnprocessedTransactionsTableRowSelectorProps {
  value: string;
  checked: boolean;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  label: string;
}

export const UnprocessedTransactionsTableRowSelector = ({
  value,
  checked,
  onChange,
  label,
}: UnprocessedTransactionsTableRowSelectorProps) => {
  return (
    <FormControlLabel
      key={""}
      value={value}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          id="showAccountsNumbers"
        />
      }
      label={label}
      sx={{
        display: "block",
        mr: 2,
        ml: 0,
        // @ts-ignore
        span: { ...commonThemes.normalText },
        "& .MuiCheckbox-root": {
          padding: 0,
        },
      }}
    />
  );
};

export const UnprocessedTransactionsTableRowAmount = ({ amount }) => {
  const isPositive = amount >= 0;
  const label = isPositive ? "Received: " : "Spent: ";
  const value = isPositive ? amount : Math.abs(amount);
  const color = isPositive ? "green" : "red";

  return (
    <Typography
      sx={{
        fontStyle: "italic",
        fontWeight: "bold",
      }}
    >
      <span>{label}</span>
      <span style={{ color: color }}>{formatFinancialNumber(value)}</span>
    </Typography>
  );
};

interface UnprocessedTransactionsTableRowActionsProps {
  onQuickAddClick: () => void;
  onNewJournal?: () => void;
  disableQuickAdd: boolean;
  onNewPayment?: () => void;
  onDisregard?: () => void;
  onMatchExisitingTransactions?: () => Promise<void>;
}

export const UnprocessedTransactionsTableRowActions = ({
  onQuickAddClick,
  disableQuickAdd,
  onNewJournal,
  onNewPayment,
  onDisregard,
  onMatchExisitingTransactions,
}: UnprocessedTransactionsTableRowActionsProps) => {
  return (
    <Box display="flex" alignItems={"center"} gap={2} flexWrap={"wrap"}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={onQuickAddClick}
        disabled={disableQuickAdd}
        startIcon={<IconPlus color="primary" />}
      >
        Quick Add test
      </Button>
      <Button
        variant="secondary"
        size="small"
        color="primary"
        startIcon={<IconMemoPencil color="primary" palleteType="main" />}
        onClick={onNewJournal}
      >
        New Journal
      </Button>
      <Button
        variant="secondary"
        size="small"
        color="primary"
        startIcon={<IconReceiptAlt3 color="primary" palleteType="main" />}
        onClick={onNewPayment}
      >
        New Payment
      </Button>
      <Button
        variant="secondary"
        size="small"
        color="primary"
        startIcon={<IconTags color="primary" palleteType="main" />}
        onClick={onMatchExisitingTransactions}
      >
        Match Exisiting Transaction
      </Button>
      <Button
        variant="secondary"
        size="small"
        color="primary"
        startIcon={<IconTrash color="primary" palleteType="main" />}
        onClick={onDisregard}
      >
        Disregard
      </Button>
    </Box>
  );
};
