// UserSelfService.jsx
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";

const UserSelfService = () => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState({
    LocalUser: {
      usrFirstName: "",
      usrLastName: "",
      usrFullName: "",
      usrEmail: "",
      usrPrivateEmail: "",
      usrNickName: "",
      usrPhone: "",
      usrGender: "",
      usrCountry: "",
      usrLang: "",
      usrPrefix: "",
      usrDOB: "",
      usrOtherNames: "",
      usrIdLabel: "",
      usrStatus: "",
      usrCode: "",
      usrPhHome: "",
      usrSite_dpsID: "",
      usrSupervisor_usrID: "",
      usrPIN: "",
      usrWorkRights_ewrID: "",
      usrVisaExpDate: "",
      usrAvailOnPubHolYNAsk: "",
      usrVerificationLevel: "",
      usrAvgCost: "",
      usrHireFee: "",
      usrPrevFirstName: "",
      usrPrevLastName: "",
      usrPrevOtherNames: "",
    },
    UserAddress: {
      adrStreet1: "",
      adrStreet2: "",
      adrTown: "",
      adrRegion: "",
      adrPostCode: "",
      adrCountry: "",
      adrAddressee: "",
      adrType_Enum5: 0,
    },
    NextOfKinPerson: {
      pplFirstName: "",
      pplLastName: "",
      pplOtherNames: "",
      pplPrefix: "",
      pplGender: "",
      pplDOB: "",
      pplWorkEmail: "",
      pplWorkEmailUse_Enum9: 0,
      pplPrivEmail: "",
      pplPrivPhone: "",
      pplSalutation: "",
    },
    NextOfKinPersonAddress: {
      adrStreet1: "",
      adrStreet2: "",
      adrTown: "",
      adrRegion: "",
      adrPostCode: "",
      adrCountry: "",
      adrAddressee: "",
      adrType_Enum5: 0,
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/user-self-service/get-user-data-async?BaseHostURL=${envConfig.mainServiceUrl}`;
      try {
        await apiCall({
          url: url,
          method: "GET",
          onSuccess: (data) => {
            setFormData(data.UserData);
            setSelectionLists(data.UserData.SelectionLists);
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      }
    };

    fetchUserData();
  }, [apiCall, enqueueSnackbar]);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/user-self-service/save-user-data-async`;
    try {
      await apiCall({
        url: url,
        method: "POST",
        body: formData,
        onSuccess: () => {
          enqueueSnackbar("User data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        User Data
      </Typography>
      <Grid container spacing={3}>
        {/* Group 1: Personal Details */}
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6">Personal Details</Typography>
          <TextField
            fullWidth
            label="Prefix"
            value={formData.LocalUser.usrPrefix}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPrefix", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="First Name"
            value={formData.LocalUser.usrFirstName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrFirstName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Additional given names"
            value={formData.LocalUser.usrOtherNames}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrOtherNames", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            value={formData.LocalUser.usrLastName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrLastName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Nickname"
            value={formData.LocalUser.usrNickName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrNickName", e.target.value)
            }
            margin="normal"
          />
        </Grid>

        {/* Group 2: Personal Details */}
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6">Personal Details</Typography>
          <TextField
            fullWidth
            label="ID"
            disabled
            value={formData.LocalUser.usrIdLabel}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrIdLabel", e.target.value)
            }
            margin="normal"
            multiline
            InputProps={{ style: { whiteSpace: "normal" } }}
          />
          <TextField
            fullWidth
            label="Code"
            disabled
            value={formData.LocalUser.usrCode}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrCode", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Date of Birth"
            type="date"
            value={
              formData.LocalUser.usrDOB
                ? formData.LocalUser.usrDOB.split("T")[0] !== "0001-01-01"
                  ? formData.LocalUser.usrDOB.split("T")[0]
                  : ""
                : ""
            }
            onChange={(e) =>
              handleInputChange("LocalUser", "usrDOB", e.target.value)
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          {/* Gender Radio Buttons */}
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="usrGender"
              value={formData.LocalUser.usrGender || ""}
              onChange={(e) =>
                handleInputChange("LocalUser", "usrGender", e.target.value)
              }
            >
              <FormControlLabel value="M" control={<Radio />} label="Male" />
              <FormControlLabel value="F" control={<Radio />} label="Female" />
              <FormControlLabel
                value="O"
                control={<Radio />}
                label="Not provided / Unknown"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Group 3: Contact Details */}
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6">Contact Details</Typography>
          <TextField
            fullWidth
            label="Email"
            value={formData.LocalUser.usrEmail}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrEmail", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Private Email"
            value={formData.LocalUser.usrPrivateEmail}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPrivateEmail", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone"
            value={formData.LocalUser.usrPhone}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPhone", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Home Phone"
            value={formData.LocalUser.usrPhHome}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPhHome", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            disabled
            label="Assigned to site"
            value={formData.LocalUser.usrSite_dpsID}
            onChange={(event, newValue) => {
              handleInputChange(
                "LocalUser",
                "usrSite_dpsID",
                event.target.value,
              );
            }}
            margin="normal"
          />
          <TextField
            fullWidth
            disabled
            value={formData.LocalUser.usrSupervisor_usrID}
            label={"Supervisor"}
            onChange={(event, newValue) => {
              handleInputChange(
                "LocalUser",
                "usrSupervisor_usrID",
                event.target.value,
              );
            }}
            margin="normal"
          />
        </Grid>

        {/* Group 4: Address */}
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h6">Address</Typography>
          <Autocomplete
            fullWidth
            options={
              selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries ||
              []
            }
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                (country) => country.Value === formData.UserAddress.adrCountry,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "UserAddress",
                "adrCountry",
                newValue ? newValue.Value : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Country"
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            label="Street 1"
            value={formData.UserAddress.adrStreet1}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrStreet1", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Street 2"
            value={formData.UserAddress.adrStreet2}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrStreet2", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Suburb / Town"
            value={formData.UserAddress.adrTown}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrTown", e.target.value)
            }
            margin="normal"
          />
          {/* State field: Conditional rendering based on country */}
          {formData.UserAddress.adrCountry === "au" ? (
            <Autocomplete
              fullWidth
              options={
                selectionLists?.DictionaryOfDisplayValueString
                  ?.ListOfAUStates || []
              }
              getOptionLabel={(option) => option.Display || ""}
              value={
                selectionLists?.DictionaryOfDisplayValueString?.ListOfAUStates?.find(
                  (state) => state.Value === formData.UserAddress.adrRegion,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange(
                  "UserAddress",
                  "adrRegion",
                  newValue ? newValue.Value : "",
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      paddingTop: "8px !important",
                      paddingBottom: "9px !important",
                    },
                    label: {
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                  label="State"
                  margin="normal"
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              label="State"
              value={formData.UserAddress.adrRegion}
              onChange={(e) =>
                handleInputChange("UserAddress", "adrRegion", e.target.value)
              }
              margin="normal"
            />
          )}
          <TextField
            fullWidth
            label="Post code"
            value={formData.UserAddress.adrPostCode}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrPostCode", e.target.value)
            }
            margin="normal"
          />
        </Grid>

        {/* Group 5: Payments */}
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6">Payments</Typography>
            <TextField
              fullWidth
              label="PIN"
              disabled
              value={formData.LocalUser.usrPIN}
              onChange={(e) =>
                handleInputChange("LocalUser", "usrPIN", e.target.value)
              }
              margin="normal"
            />
            <Autocomplete
              fullWidth
              options={selectionLists?.ListOfWorkRights || []}
              getOptionLabel={(option) => option.Display || ""}
              value={
                selectionLists?.ListOfWorkRights?.find(
                  (wr) => wr.Value === formData.LocalUser.usrWorkRights_ewrID,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange(
                  "LocalUser",
                  "usrWorkRights_ewrID",
                  newValue ? newValue.Value : "",
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Work rights status"
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      paddingTop: "8px !important",
                      paddingBottom: "9px !important",
                    },
                    label: {
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                  margin="normal"
                />
              )}
            />
            <TextField
              fullWidth
              label="Visa expiry (if applicable)"
              type="date"
              value={
                formData.LocalUser.usrVisaExpDate
                  ? formData.LocalUser.usrVisaExpDate.split("T")[0] !==
                    "0001-01-01"
                    ? formData.LocalUser.usrVisaExpDate.split("T")[0]
                    : ""
                  : ""
              }
              onChange={(e) =>
                handleInputChange("LocalUser", "usrVisaExpDate", e.target.value)
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            {/* Ready to work on Public Holidays: Radio Buttons */}
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">
                Ready to work on Public Holidays
              </FormLabel>
              <RadioGroup
                row
                aria-label="usrAvailOnPubHolYNAsk"
                name="usrAvailOnPubHolYNAsk"
                value={formData.LocalUser.usrAvailOnPubHolYNAsk || ""}
                onChange={(e) =>
                  handleInputChange(
                    "LocalUser",
                    "usrAvailOnPubHolYNAsk",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                <FormControlLabel value="N" control={<Radio />} label="No" />
                <FormControlLabel value="A" control={<Radio />} label="Maybe" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Group 6: Other Details */}
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6">Other Details</Typography>
            {/* <Autocomplete
              fullWidth
              options={
                selectionLists?.DictionaryOfDisplayValueString
                  ?.ListOfLanguages || []
              }
              getOptionLabel={(option) => option.Display || ""}
              value={
                selectionLists?.DictionaryOfDisplayValueString?.ListOfLanguages?.find(
                  (lang) => lang.Value === formData.LocalUser.usrLang,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange(
                  "LocalUser",
                  "usrLang",
                  newValue ? newValue.Value : "",
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      paddingTop: "8px !important",
                      paddingBottom: "9px !important",
                    },
                    label: {
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                  label="Primary language"
                  margin="normal"
                />
              )}
            /> */}
            <TextField
              fullWidth
              label="Previous first name"
              value={formData.LocalUser.usrPrevFirstName}
              onChange={(e) =>
                handleInputChange(
                  "LocalUser",
                  "usrPrevFirstName",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Previous last name"
              value={formData.LocalUser.usrPrevLastName}
              onChange={(e) =>
                handleInputChange(
                  "LocalUser",
                  "usrPrevLastName",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Previous other names"
              value={formData.LocalUser.usrPrevOtherNames}
              onChange={(e) =>
                handleInputChange(
                  "LocalUser",
                  "usrPrevOtherNames",
                  e.target.value,
                )
              }
              margin="normal"
            />
          </Grid>

          {/* Group 7 and 8: Next of Kin */}

          {/* Group 7: Next of Kin */}
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6">Next of Kin</Typography>
            <TextField
              fullWidth
              label="Prefix"
              value={formData.NextOfKinPerson.pplPrefix}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPerson",
                  "pplPrefix",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="First name"
              value={formData.NextOfKinPerson.pplFirstName}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPerson",
                  "pplFirstName",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last name"
              value={formData.NextOfKinPerson.pplLastName}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPerson",
                  "pplLastName",
                  e.target.value,
                )
              }
              margin="normal"
            />
            {/* Gender Radio Buttons */}
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                aria-label="pplGender"
                name="pplGender"
                value={formData.NextOfKinPerson.pplGender || ""}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPerson",
                    "pplGender",
                    e.target.value,
                  )
                }
              >
                <FormControlLabel value="M" control={<Radio />} label="Male" />
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="O"
                  control={<Radio />}
                  label="Not provided / Unknown"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth
              label="Email"
              value={formData.NextOfKinPerson.pplPrivEmail}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPerson",
                  "pplPrivEmail",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              type="tel"
              label="Contact phone number"
              value={formData.NextOfKinPerson.pplPrivPhone}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPerson",
                  "pplPrivPhone",
                  e.target.value,
                )
              }
              margin="normal"
            />
          </Grid>

          {/* Group 8: Next of Kin Address */}
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6">Next of Kin - Address</Typography>
            <Autocomplete
              fullWidth
              options={
                selectionLists?.DictionaryOfDisplayValueString
                  ?.ListOfCountries || []
              }
              getOptionLabel={(option) => option.Display || ""}
              value={
                selectionLists?.DictionaryOfDisplayValueString?.ListOfCountries?.find(
                  (country) =>
                    country.Value ===
                    formData.NextOfKinPersonAddress.adrCountry,
                ) || null
              }
              onChange={(event, newValue) => {
                handleInputChange(
                  "NextOfKinPersonAddress",
                  "adrCountry",
                  newValue ? newValue.Value : "",
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      paddingTop: "8px !important",
                      paddingBottom: "9px !important",
                    },
                    label: {
                      ...commonThemes.inputLabel,
                      color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                    },
                  }}
                  label="Country"
                  margin="normal"
                />
              )}
            />
            <TextField
              fullWidth
              label="Street 1"
              value={formData.NextOfKinPersonAddress.adrStreet1}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPersonAddress",
                  "adrStreet1",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Street 2"
              value={formData.NextOfKinPersonAddress.adrStreet2}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPersonAddress",
                  "adrStreet2",
                  e.target.value,
                )
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Suburb / Town"
              value={formData.NextOfKinPersonAddress.adrTown}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPersonAddress",
                  "adrTown",
                  e.target.value,
                )
              }
              margin="normal"
            />
            {/* State field: Conditional rendering based on country */}
            {formData.NextOfKinPersonAddress.adrCountry === "au" ? (
              <Autocomplete
                fullWidth
                options={
                  selectionLists?.DictionaryOfDisplayValueString
                    ?.ListOfAUStates || []
                }
                getOptionLabel={(option) => option.Display || ""}
                value={
                  selectionLists?.DictionaryOfDisplayValueString?.ListOfAUStates?.find(
                    (state) =>
                      state.Value === formData.NextOfKinPersonAddress.adrRegion,
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrRegion",
                    newValue ? newValue.Value : "",
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      ".MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingTop: "8px !important",
                        paddingBottom: "9px !important",
                      },
                      label: {
                        ...commonThemes.inputLabel,
                        color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    label="State"
                    margin="normal"
                  />
                )}
              />
            ) : (
              <TextField
                fullWidth
                label="State"
                value={formData.NextOfKinPersonAddress.adrRegion}
                onChange={(e) =>
                  handleInputChange(
                    "NextOfKinPersonAddress",
                    "adrRegion",
                    e.target.value,
                  )
                }
                margin="normal"
              />
            )}
            <TextField
              fullWidth
              label="Post code"
              value={formData.NextOfKinPersonAddress.adrPostCode}
              onChange={(e) =>
                handleInputChange(
                  "NextOfKinPersonAddress",
                  "adrPostCode",
                  e.target.value,
                )
              }
              margin="normal"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default UserSelfService;
