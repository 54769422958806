import React from "react";
import envConfig from "../../../config";
import { useQuery } from "../../../hooks/useQuery";

const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/get?BaseHostURL=${envConfig.mainServiceUrl}`;

export const EXAMPLE_RESPONSE_BUSINESS_DETAILS = {
  SuccessYN: "Y",
  ErrorMessage: "",
  Details: {
    meeID: 1,
    meeName: "Demo BTMH Pty Ltd 2024-02-04 08:30:06",
    meeRef: "",
    meeTaxCode: "",
    meePh1No: "",
    meePh1Desc: "Office:",
    meePh2No: "",
    meePh2Desc: "Mobile:",
    meePh3No: "",
    meePh3Desc: "Other:",
    meeEmail: "tm@btmh.com.au",
    meeAddress1: "104/109 Oxford Street",
    meeAddress2: "",
    meeSuburb: "Bondi Junction",
    meeState: "NSW",
    meePostCode: "2022",
    meeAddressCountry: "au",
    meeBankCode: "123123",
    meeBankAcct: "123123123",
    meeBankAcName: "tm test",
    meeBankBIC: "",
    meeBankRef: "CBA",
    meeBankCountry: "au",
    meeContactName: "Tom Murmylo",
    meeBranchCode: "1",
    meeBankUserID: "301500",
    meePayPalBusinessID: "",
    meeCompanyWebsite: "",
    meeDebug: "N",
    meeEntityType_cnfFrmID: 3,
    meeRegisteredVAT: "Y",
    meeActivity_xbaCode: "n/a",
    meeAbaBalancingYN: "Y",
    meePayID: "",
    meeStripeCharge: 0.0,
    meeStripeAccId: "",
    meeStripePmtMethod_pmmID: 7,
    meeStripePmtAccountNo_accNo: 1031,
    meeStripeStatus: "Y",
    meeLogo: null,
  },
  ListOfPaymentMethods: [
    {
      pmmID: 99,
      pmmName: "Bad Debt write-off",
      pmmActiveYN: "Y",
      pmm_accNo: 0,
    },
    { pmmID: 2, pmmName: "Cash", pmmActiveYN: "Y", pmm_accNo: 1020 },
    { pmmID: 6, pmmName: "Cheque", pmmActiveYN: "Y", pmm_accNo: 1000 },
    { pmmID: 1, pmmName: "Direct Deposit", pmmActiveYN: "Y", pmm_accNo: 1000 },
    { pmmID: 3, pmmName: "EFTPOS", pmmActiveYN: "Y", pmm_accNo: 1030 },
    { pmmID: 7, pmmName: "Online CC", pmmActiveYN: "Y", pmm_accNo: 1031 },
    { pmmID: 100, pmmName: "Online CC", pmmActiveYN: "Y", pmm_accNo: 1031 },
    {
      pmmID: 4,
      pmmName: "Payment under Authority",
      pmmActiveYN: "Y",
      pmm_accNo: 0,
    },
    { pmmID: 5, pmmName: "PayPal", pmmActiveYN: "Y", pmm_accNo: 1030 },
  ],
  ListOfCurrentAssetAccounts: [
    { accNo: 1000, accName: "Cheque Account", accType: "Current Assets" },
    { accNo: 1010, accName: "Savings Account", accType: "Current Assets" },
    { accNo: 1020, accName: "Cash in hand", accType: "Current Assets" },
    { accNo: 1030, accName: "Clearing account", accType: "Current Assets" },
    {
      accNo: 1031,
      accName: "Online Payments account",
      accType: "Current Assets",
    },
    { accNo: 1100, accName: "Trade debtors", accType: "Current Assets" },
    { accNo: 1110, accName: "Deposits paid", accType: "Current Assets" },
    { accNo: 1200, accName: "Stock in hand", accType: "Current Assets" },
    { accNo: 1410, accName: "Other current assets", accType: "Current Assets" },
  ],
  ListOfExpensesAccounts: [
    { accNo: 6110, accName: "Accounting Fees x", accType: "Expenses" },
    { accNo: 6111, accName: "test new acc", accType: "Expenses" },
    { accNo: 6120, accName: "Advertising", accType: "Expenses" },
    { accNo: 6130, accName: "Audit fees", accType: "Expenses" },
    { accNo: 6140, accName: "Filing fees", accType: "Expenses" },
    { accNo: 6150, accName: "Legal fees", accType: "Expenses" },
    { accNo: 6160, accName: "Other professional fees", accType: "Expenses" },
    { accNo: 6170, accName: "Compliance costs", accType: "Expenses" },
    { accNo: 6190, accName: "Bad debts", accType: "Expenses" },
    { accNo: 6210, accName: "Bank Charges", accType: "Expenses" },
    { accNo: 6220, accName: "Merchant fees", accType: "Expenses" },
    { accNo: 6310, accName: "Office Supplies", accType: "Expenses" },
    {
      accNo: 6320,
      accName: "Website & online subscriptions",
      accType: "Expenses",
    },
    { accNo: 6330, accName: "IT and Computer Expenses", accType: "Expenses" },
    {
      accNo: 6350,
      accName: "Consulting & Management fees",
      accType: "Expenses",
    },
    { accNo: 6360, accName: "Repairs & Maintenance", accType: "Expenses" },
    {
      accNo: 6370,
      accName: "Memberships and subscriptions",
      accType: "Expenses",
    },
    { accNo: 6390, accName: "Service fees", accType: "Expenses" },
    { accNo: 6410, accName: "Tools & Equipment", accType: "Expenses" },
    { accNo: 6420, accName: "Travel & business meetings", accType: "Expenses" },
    { accNo: 6430, accName: "Entertainment", accType: "Expenses" },
    { accNo: 6440, accName: "General expenses", accType: "Expenses" },
    { accNo: 6450, accName: "Hire or lease of equipment", accType: "Expenses" },
    { accNo: 6460, accName: "Gifts & donations", accType: "Expenses" },
    { accNo: 6510, accName: "Telephone & Internet", accType: "Expenses" },
    { accNo: 6520, accName: "Insurance", accType: "Expenses" },
    { accNo: 6550, accName: "Rent", accType: "Expenses" },
    { accNo: 6560, accName: "Utilities", accType: "Expenses" },
    { accNo: 6610, accName: "Wages & Salaries", accType: "Expenses" },
    { accNo: 6620, accName: "Superannuation expense", accType: "Expenses" },
    { accNo: 6630, accName: "Allowances", accType: "Expenses" },
    { accNo: 6640, accName: "Trainings & Conferences", accType: "Expenses" },
    { accNo: 6650, accName: "Staff amenities", accType: "Expenses" },
    { accNo: 6660, accName: "Other employment expenses", accType: "Expenses" },
    { accNo: 6710, accName: "Car fuel", accType: "Expenses" },
    { accNo: 6720, accName: "Car services", accType: "Expenses" },
    { accNo: 6730, accName: "Car rego & insurances", accType: "Expenses" },
    { accNo: 6740, accName: "Car - other expenses", accType: "Expenses" },
    { accNo: 6750, accName: "Car - finance costs", accType: "Expenses" },
    {
      accNo: 6770,
      accName: "Car - repairs & maintenance",
      accType: "Expenses",
    },
    { accNo: 6790, accName: "Tolls & parking", accType: "Expenses" },
    { accNo: 6820, accName: "Fines and penalties", accType: "Expenses" },
  ],
};

interface BusinessDetails {
  meeID: number;
  meeName: string;
  meeRef: string;
  meeTaxCode: string;
  meePh1No: string;
  meePh1Desc: string;
  meePh2No: string;
  meePh2Desc: string;
  meePh3No: string;
  meePh3Desc: string;
  meeEmail: string;
  meeAddress1: string;
  meeAddress2: string;
  meeSuburb: string;
  meeState: string;
  meePostCode: string;
  meeAddressCountry: string;
  meeBankCode: string;
  meeBankAcct: string;
  meeBankAcName: string;
  meeBankBIC: string;
  meeBankRef: string;
  meeBankCountry: string;
  meeContactName: string;
  meeBranchCode: string;
  meeBankUserID: string;
  meePayPalBusinessID: string;
  meeCompanyWebsite: string;
  meeDebug: string;
  meeEntityType_cnfFrmID: number;
  meeRegisteredVAT: string;
  meeActivity_xbaCode: string;
  meeAbaBalancingYN: string;
  meePayID: string;
  meeStripeCharge: number;
  meeStripeAccId: string;
  meeStripePmtMethod_pmmID: number;
  meeStripePmtAccountNo_accNo: number;
  meeStripeStatus: string;
  meeLogo: {
    meeImageLogoBase64: string;
  };
}

interface BusinessDetailsResponse {
  SuccessYN: string;
  ErrorMessage: string;
  Details: BusinessDetails;
}

export const useBusinessDetails = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: BusinessDetailsResponse) => void;
  onError?: (error: any) => void;
}) => {
  const { isLoading, isError, error, data } = useQuery<BusinessDetailsResponse>(
    url,
    {
      onSuccess: onSuccess,
      onError: onError,
    },
  );

  if (process.env.NODE_ENV === "development") {
    return {
      isLoading: false,
      isError: false,
      error: null,
      data: {
        SuccessYN: "Y",
        ErrorMessage: "",
        Details: {
          meeID: 1,
          meeName: "Summer Ptd Ltd",
          meeRef: "SUM12",
          meeTaxCode: "4654545454454",
          meePh1No: "0415584541",
          meePh1Desc: "t",
          meePh2No: "+64154548545",
          meePh2Desc: "fax",
          meePh3No: "",
          meePh3Desc: "",
          meeEmail: "info@google.com",
          meeAddress1: "56 Kent Street",
          meeAddress2: "",
          meeSuburb: "Sydney",
          meeState: "NSW",
          meePostCode: "2221",
          meeAddressCountry: "au",
          meeBankCode: "132 345",
          meeBankAcct: "4556 566",
          meeBankAcName: "Westpac",
          meeBankBIC: "WTF",
          meeBankRef: "123",
          meeBankCountry: "au",
          meeContactName: "John",
          meeBranchCode: "1",
          meeBankUserID: "155-456",
          meePayPalBusinessID: "45666-4454",
          meeCompanyWebsite: "www.btmh.com.au",
          meeDebug: "Y",
          meeEntityType_cnfFrmID: 1,
          meeRegisteredVAT: "Y",
          meeActivity_xbaCode: "n/a",
          meeAbaBalancingYN: "N",
          meePayID: "5698-5",
          meeStripeCharge: 0,
          meeStripeAccId: "b62bccfb-e935-4fc0-a78e-0748fc060d2b",
          meeStripePmtMethod_pmmID: 12,
          meeStripePmtAccountNo_accNo: 1000,
          meeStripeStatus: "A",
          meeLogo: {
            meeImageLogoBase64: "data:image/png;base64,",
          },
        },
      },
    };
  }

  return { isLoading, isError, error, data };
};
