import React from "react";
import envConfig from "../../../config";
import { useSnackbar } from "notistack";
import { EXAMPLE_GET_CONTACTS_RESPONSE } from "../../../data/example-get-contacts-response";
import useApi from "../../../hooks/useApi";
import { ResponseBoolean } from "../../../types/api/common";

const contactsUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/contacts/get-selection-list?activeYN=Y&typeCS=C&BaseHostURL=${envConfig.mainServiceUrl}`;

// TODO: Move it somewhere else - probably types
export interface Contact {
  cntId: number;
  cntCode: string;
  cntName: string;
  // cntTaxCode: string;
  // cntTaxCodeFull: string;
  // cntEmail: string;
  // cntPhoneNo: string;
}

export interface ContactsResponse {
  ListOfContacts: Contact[];
  errorMessage: ResponseBoolean;
  successYN: ResponseBoolean;
}

export const useListOfContacts = () => {
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<ContactsResponse | undefined>(
    undefined,
  );
  const [isLoadingContacts, setIsLoadingContacts] = React.useState(false);
  const [isErrorContacts, setIsErrorContacts] = React.useState(false);

  const fetchListOfContacts = React.useCallback(async () => {
    setIsLoadingContacts(true);
    setIsErrorContacts(false);

    try {
      await apiCall({
        url: contactsUrl,
        method: "GET",
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onSuccess: (data: ContactsResponse) => {
          setData(data);
          // if (onSuccess) onSuccess(data);
        },
        // @ts-ignore + TODO-FUTURE: add TypeScript support for this
        onError: (error: string) => {
          setIsErrorContacts(true);
          enqueueSnackbar(error, { variant: "error" });
          // if (onError) onError(error);
        },
      });
    } catch (error) {
      console.error("fetchListOfContacts error", error);
      setIsErrorContacts(true);
      enqueueSnackbar((error as Error).message, { variant: "error" });
      // if (onError) onError((error as Error).message);
    } finally {
      if (process.env.NODE_ENV === "development") {
        setData(EXAMPLE_GET_CONTACTS_RESPONSE as unknown as ContactsResponse);
      }
      setIsLoadingContacts(false);
    }
  }, [apiCall, enqueueSnackbar]);

  React.useEffect(() => {
    fetchListOfContacts();
  }, [fetchListOfContacts]);

  return {
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    data,
    refetch: fetchListOfContacts,
  };
};
