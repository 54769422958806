import { useEffect, useState } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { ReactComponent as PlusIcon } from "../../assets/plus-in-circle.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as RefreshIcon } from "../../assets/refresh.svg";
import { ReactComponent as FullscreenIcon } from "../../assets/full-size.svg";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { commonThemes, darkTheme, lightTheme } from "../../utils/themes/themes";
import { useTheme } from "../../context/ThemeContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import numeral from "numeral";
import { createSessionForExternal, downloadFile } from "../../utils/reusable";
import Papa from "papaparse";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import { formatDateToISO } from "../../utils/format-date";
import GridDownloadExcelPdfButtons from "../GridDownloadExcelPdfButtons/GridDownloadExcelPdfButtons";

LicenseInfo.setLicenseKey(
  "d4fd116dcdd62ee808f5368ad91259faTz03ODUxNCxFPTE3MzEyNzMxNTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

export const FullscreenPNL = ({
  openFullscreen,
  setOpenFullscreen = () => {},
  rows,
  columns,
  showDownloadDate,
  loading,
  gridData,
  isMobilePhone,
}) => {
  const navigate = useNavigate();
  const tableRef = useGridApiRef();
  const { isDarkMode } = useTheme();

  return (
    <Dialog
      sx={{
        ">div>div": { p: 0, minWidth: "97vw", overflowY: "visible" },
        maxWidth: "unset",
      }}
      open={openFullscreen}
      onClose={() => setOpenFullscreen(false)}
    >
      <DialogContent sx={{ p: 0, minWidth: "80vw", overflowY: "hidden" }}>
        <IconButton
          edge="end"
          color="primary"
          onClick={() => setOpenFullscreen(false)}
          aria-label="close"
          sx={{
            bgcolor: "white",
            borderRadius: "50%, 50%",
            position: "absolute",
            right: "0",
            top: "-18px",
            padding: "4px",
            background: isDarkMode ? "#121212" : "#fff",
            "&:hover": {
              background: isDarkMode ? "#383838" : "#FAFAFA",
            },
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <Box
          sx={{
            transition: "all 1s ease",
            width: openFullscreen ? "calc(100vw - 16px)" : "100%",
            position: "relative",
            left: openFullscreen ? "50%" : 0,
            right: openFullscreen ? "50%" : 0,
            marginLeft: openFullscreen ? "-50vw" : 0,
            marginRight: openFullscreen ? "-50vw" : 0,
            ".MuiBox-root > .MuiDataGrid-root": {
              marginTop: 0,
            },
          }}
        >
          <DataGridPremium
            disableRowSelectionOnClick={true}
            loading={loading}
            apiRef={tableRef}
            getCellClassName={() => "column-border"}
            slots={{
              toolbar: () => <></>,
            }}
            columns={columns}
            rows={rows}
            rowHeight={40}
            initialState={
              isMobilePhone
                ? {
                    pinnedColumns: {
                      left: ["accNo"],
                    },
                  }
                : {
                    pinnedColumns: {
                      left: ["AccountName"],
                    },
                  }
            }
            hideFooter={true}
            columnVisibilityModel={
              isMobilePhone
                ? {
                    RowNo: false,
                    RowType: false,
                    AccountName: false,
                  }
                : {
                    RowNo: false,
                    RowType: false,
                    accNo: false,
                  }
            }
            getRowClassName={(params) => {
              switch (params.row.RowType) {
                case "H":
                  return "bold";
                case "D":
                  return `indent ${
                    isDarkMode
                      ? "underline-thicker-dark"
                      : "underline-thicker-light"
                  }`;
                case "F":
                  return "bold underline";
                case "S":
                  return "spaceholder";
                default:
                  return "";
              }
            }}
            sx={{
              ".MuiDataGrid-pinnedColumnHeaders": {
                background: isDarkMode
                  ? "#121212 !important"
                  : "#FFF !important",
              },
              ".MuiDataGrid-pinnedColumns": {
                background: isDarkMode
                  ? "#121212 !important"
                  : "#FFF !important",
              },
              ".MuiDataGrid-main": {
                // maxWidth: "calc(100% - 230px)",
              },
              "div.MuiDataGrid-virtualScroller": {
                // maxWidth: "calc(100% - 230px)",
                // overflowY: "auto",
                minWidth: "100%",
                maxHeight: "82vh",
                minHeight: columns?.length === 0 ? "50px" : "unset",
                paddingTop: columns?.length === 0 ? "25px" : 0,
                // ">div": {
                //   height: "401px !important",
                // },
              },
              "div.MuiDataGrid-columnHeaders": {
                // maxWidth: columns?.length > 0 ? "calc(100% - 230px)" : "unset",
                display: columns?.length > 0 ? "flex" : "none",
              },
              "@media print": {
                "@page": { margin: showDownloadDate ? "auto" : 0 },
                body: { margin: showDownloadDate ? "auto" : "1.6cm" },
                ".print-hide": {
                  display: "none",
                },
                ".MuiDataGrid-main": {
                  width: "max-content",
                  height: "max-content",
                  // overflow: "visible",
                  color: "black !important",
                },
              },
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
              border: "none",
              borderRadius: "8px",
              mt: "10px",
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              ".MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none !important",
              },
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const BalanceSheetTable = ({
  loading,
  gridData,
  onAddEarlierPeriod = () => {},
  onApply = () => {},
  generatePDF = () => {},
  sortBy,
  setSortBy = () => {},
  showDownloadDate,
  setShowDownloadDate = () => {},
  showAccountsNumbers,
  openFullscreen,
  setOpenFullscreen,
}) => {
  const { apiCall } = useApi();
  const { dbId } = useParams();
  const apiRef = useGridApiRef();
  const isScreenBelow1050px = useMediaQuery("(max-width: 1050px)");

  const isMobilePhone = useMediaQuery("(max-width: 847px)");
  const gridButtonsMedia = useMediaQuery("(max-width: 525px)");
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [open, setOpen] = useState(false);
  const [format, setFormat] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [initialState, setInitialState] = useState({});
  const [columnsConfig, setColumnsConfig] = useState({});
  const [ip, setIp] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenuData, setContextMenuData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const responseIp = await fetch("https://api.ipify.org?format=json");
      const dataIp = await responseIp.json();
      setIp(dataIp?.ip);
    };
    if (!ip) fetchData();
  }, []);

  const handleContextMenu = (event, data) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setContextMenuData(data);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
    setContextMenuData(null);
  };

  const handleOpenInNewTab = () => {
    if (contextMenuData) {
      createSessionForExternal(
        contextMenuData.link,
        ip,
        true, // Open in new tab
        apiCall,
        dbId,
      );
      handleCloseContextMenu();
    }
  };

  useEffect(() => {
    if (gridData?.DataDT) {
      setRows(
        gridData.DataDT.map((row, index) => {
          let modifiedRow = {
            id: index,
            ...row,
          };
          if (
            showAccountsNumbers &&
            row.accNo &&
            typeof row.accNo === "number"
          ) {
            modifiedRow.AccountName = row.accNo + " " + row.AccountName;
          }
          return modifiedRow;
        }),
      );
    } else {
      setRows([]);
    }
    if (gridData?.ColumnsList && gridData?.DataDT) {
      const cols = gridData.ColumnsList.map((column) => {
        return {
          field: column.RpcDataSource,
          headerName: column.RpcHeader,
          headerClassName:
            column.RpcAlign === 1
              ? "header-align-left grid-header-title "
              : column.rpcAlign === 2
                ? "header-align-center grid-header-title "
                : "header-align-right grid-header-title ",
          align:
            column.RpcAlign === 1
              ? "left"
              : column.RpcAlign === 2
                ? "center"
                : "right",
          minWidth:
            column.RpcDataSource === "accNo"
              ? 90
              : column.RpcHeader === "Account"
                ? 280
                : 155,
          maxWidth:
            column.RpcDataSource === "accNo"
              ? 90
              : column.RpcHeader === "Account"
                ? 280
                : 155,
          sortable: false,
          wrapText: column.RpcWrapText === "Y",
          resizable: false,
          disableColumnMenu: true,
          flex: 1,
          renderCell: (params) => {
            const accNo = params.row.accNo;
            const rpcDataSource = column.RpcDataSource;
            const endDate = rpcDataSource.slice(1, 7);
            const formattedEndDate = `20${endDate.slice(0, 2)}-${endDate.slice(2, 4)}-${endDate.slice(4)}`;

            const endDateObj = new Date(formattedEndDate);
            let startDate = new Date(endDateObj.getFullYear(), 6, 1); // 01/07 of the same year
            if (endDateObj < startDate) {
              startDate.setFullYear(endDateObj.getFullYear() - 1); // If endDate is before 01/07, use the previous year
            }

            const formattedStartDate = `${String(startDate.getDate()).padStart(2, "0")}/${String(startDate.getMonth() + 1).padStart(2, "0")}/${String(startDate.getFullYear()).slice(-2)}`;
            const formattedEndDateDDMMYY = `${String(endDateObj.getDate()).padStart(2, "0")}/${String(endDateObj.getMonth() + 1).padStart(2, "0")}/${String(endDateObj.getFullYear()).slice(-2)}`;

            if (rpcDataSource === "AccountName") {
              // if (showAccountsNumbers && accNo && typeof accNo === "number") {
              return <div>{params.value}</div>;
              // }
              // return <div>{params.value}</div>;
            }

            if (rpcDataSource === "accNo") {
              return accNo && typeof accNo === "number" ? (
                <Link
                  style={{
                    color: "inherit",
                    textDecoration: "inherit",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                  onContextMenu={(e) => {
                    handleContextMenu(e, {
                      link: `https://${envConfig.mainServiceUrl}/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDateDDMMYY}`,
                    });
                  }}
                  to={`https://${envConfig.mainServiceUrl}/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDateDDMMYY}`}
                >
                  {params.value}
                </Link>
              ) : (
                <div>{params.value || ""}</div>
              );
            }

            return (
              <>
                {accNo && typeof params.value === "number" ? (
                  <Link
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    onContextMenu={(e) => {
                      handleContextMenu(e, {
                        link: `https://${envConfig.mainServiceUrl}/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDateDDMMYY}`,
                      });
                    }}
                    to={`https://${envConfig.mainServiceUrl}/${dbId}/en-au/reports/general-ledger?accNo=${accNo}&startDate=${formattedStartDate}&endDate=${formattedEndDateDDMMYY}`}
                  >
                    {typeof params.value === "number"
                      ? params.value < 0
                        ? `(${numeral(Math.abs(params.value)).format(
                            "0,0.00",
                          )})`
                        : params.value === 0 || params.value === "0"
                          ? "-"
                          : `${numeral(Math.abs(params.value)).format(
                              "0,0.00",
                            )}`
                      : params.value}
                  </Link>
                ) : (
                  <div>
                    {typeof params.value === "number"
                      ? params.value < 0
                        ? `(${numeral(Math.abs(params.value)).format(
                            "0,0.00",
                          )})`
                        : params.value === 0 || params.value === "0"
                          ? "-"
                          : `${numeral(Math.abs(params.value)).format(
                              "0,0.00",
                            )}`
                      : params.value}
                  </div>
                )}
              </>
            );
          },
        };
      });
      setColumns(cols);
    } else {
      setColumns([]);
    }
  }, [gridData, showAccountsNumbers]);

  const handleClickOpen = (format) => {
    setFormat(format);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddEarlierPeriod = () => {
    onAddEarlierPeriod();
  };

  const handleDownloadExcel = async () => {
    apiRef.current.exportDataAsExcel({
      fields: [
        ...columns
          .map((col) => {
            if (
              col.field !== "accNo" &&
              col.field !== "RowNo" &&
              col.field !== "RowType"
            ) {
              return col.field;
            }
            return null;
          })
          .filter(Boolean),
      ],
    });
  };

  const handleDownloadPDF = async () => {
    setLoadingPDF(true);
    await generatePDF();
    setLoadingPDF(false);
  };

  useEffect(() => {
    setInitialState(
      isMobilePhone
        ? {
            pinnedColumns: {
              left: ["accNo"],
            },
          }
        : {
            pinnedColumns: {
              left: ["AccountName"],
            },
          },
    );

    setColumnsConfig(
      isMobilePhone
        ? {
            RowNo: false,
            RowType: false,
            AccountName: false,
          }
        : {
            RowNo: false,
            RowType: false,
            accNo: false,
          },
    );
  }, [isMobilePhone]);

  return (
    <div style={{ width: "100%", marginBottom: 100, marginTop: 86 }}>
      <Dialog sx={{ ">div>div": { p: 4 } }} open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ ...commonThemes.popupTitle, p: 0 }}
        >{`Download to ${format}`}</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ mb: 2, mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!showDownloadDate}
                  onChange={() => setShowDownloadDate(!showDownloadDate)}
                  id="showDownloadDate"
                />
              }
              sx={{ span: { ...commonThemes.normalText } }}
              label="Show Download Date"
            />
          </Box>
          {format === "PDF" && (
            <FormControl component="fieldset">
              <FormLabel sx={{ ...theme.blueLabel }} component="legend">
                Sort By
              </FormLabel>
              <RadioGroup
                aria-label="sort by"
                value={sortBy}
                sx={{ display: "flex", flexDirection: "row", mt: -0.5 }}
                onChange={(event) => setSortBy(event.target.value)}
              >
                <FormControlLabel
                  value="Account Number"
                  sx={{ span: { ...commonThemes.normalText } }}
                  control={<Radio />}
                  label="Account Number"
                />
                <FormControlLabel
                  value="Account Name"
                  sx={{ span: { ...commonThemes.normalText } }}
                  control={<Radio />}
                  label="Account Name"
                />
              </RadioGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: "30px" }}>
          <Button
            variant="secondary"
            sx={{
              textTransform: "none",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "rgba(132, 159, 35, 0.04)",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading || loadingPDF}
            sx={{
              textTransform: "none",
              "&.MuiButtonBase-root:hover": {
                bgcolor: theme.palette.primary.main,
              },
            }}
            onClick={handleDownloadPDF}
          >
            Download Report
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          transition: "all 1s ease",
          width: openFullscreen ? "calc(100vw - 8px)" : "100%",
          position: "relative",
          left: openFullscreen ? "50%" : 0,
          right: openFullscreen ? "50%" : 0,
          marginLeft: openFullscreen ? "-50vw" : 0,
          marginRight: openFullscreen ? "-50vw" : 0,
          paddingLeft: openFullscreen ? "10px" : 0,
          paddingRight: openFullscreen ? "10px" : 0,
          ".MuiBox-root > .MuiDataGrid-root": {
            marginTop: 0,
          },
        }}
      >
        {columns?.length > 0 ? (
          <DataGridPremium
            disableRowSelectionOnClick={true}
            loading={loading}
            apiRef={apiRef}
            getCellClassName={() => "column-border"}
            slots={{
              toolbar: () => (
                <Box
                  className="print-hide"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "1rem",
                    alignItems: "center",
                    marginRight: "16px",
                    marginBottom: 0,
                    position: "absolute",
                    top: gridButtonsMedia ? "-60px" : "-47px",
                    width: "100%",
                  }}
                >
                  <Box
                    onClick={() => !loading && onApply()}
                    className="print-hide"
                    sx={{
                      marginLeft: isScreenBelow1050px ? "0" : "16px",
                      ...commonThemes.normalText,
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: 700,
                      pr: "2px",
                      mr: isMobilePhone ? 0 : 5,
                      "&:hover": {
                        bgcolor: "rgba(132, 159, 35, 0.04)",
                      },
                    }}
                  >
                    <RefreshIcon style={{ marginRight: "8px" }} />
                    Refresh
                  </Box>
                  <GridDownloadExcelPdfButtons
                    downloadExcel={handleDownloadExcel}
                    downloadPDF={() => handleClickOpen("PDF")}
                    displayDownloadPDF={
                      gridData?.ColumnsList?.length === 5 ||
                      gridData?.ColumnsList?.length === 6
                    }
                  />
                  <Box
                    sx={{
                      flex: 1,
                      textAlign: "right",
                    }}
                  >
                    <Button
                      className="print-hide"
                      style={{ fontWeight: 700 }}
                      onClick={() => setOpenFullscreen((prev) => !prev)}
                    >
                      <FullscreenIcon style={{ marginRight: "8px" }} />
                      Full Screen
                    </Button>
                  </Box>
                </Box>
              ),
            }}
            sx={{
              ".MuiDataGrid-pinnedColumnHeaders": {
                background: isDarkMode
                  ? "#121212 !important"
                  : "#FFF !important",
              },
              ".MuiDataGrid-pinnedColumns": {
                background: isDarkMode
                  ? "#121212 !important"
                  : "#FFF !important",
              },
              ".MuiDataGrid-main": {
                // maxWidth: "calc(100% - 230px)",
              },
              "div.MuiDataGrid-virtualScroller": {
                // maxWidth: "calc(100% - 230px)",
                // overflowY: "auto",
                // maxHeight: "80vh",
                paddingBottom: "30px",
                minWidth: "100%",
                minHeight: columns?.length === 0 ? "50px" : "unset",
                paddingTop: columns?.length === 0 ? "25px" : 0,
                // ">div": {
                //   height: "401px !important",
                // },
              },
              "div.MuiDataGrid-columnHeaders": {
                // maxWidth: columns?.length > 0 ? "calc(100% - 230px)" : "unset",
                display: columns?.length > 0 ? "flex" : "none",
              },
              "@media print": {
                "@page": { margin: showDownloadDate ? "auto" : 0 },
                body: { margin: showDownloadDate ? "auto" : "1.6cm" },
                ".print-hide": {
                  display: "none",
                },
                ".MuiDataGrid-main": {
                  width: "max-content",
                  height: "max-content",
                  // overflow: "visible",
                  color: "black !important",
                },
              },
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
              border: "none",
              borderRadius: "8px",
              mt: "10px",
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              ".MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none !important",
              },
            }}
            rowHeight={40}
            rows={rows}
            columns={columns}
            hideFooter={true}
            columnVisibilityModel={columnsConfig}
            initialState={initialState}
            getRowClassName={(params) => {
              switch (params.row.RowType) {
                case "H":
                  return "bold";
                case "D":
                  return `indent ${
                    isDarkMode
                      ? "underline-thicker-dark"
                      : "underline-thicker-light"
                  }`;
                case "F":
                  return "bold underline";
                case "S":
                  return "spaceholder";
                default:
                  return "";
              }
            }}
          />
        ) : (
          <Stack
            className=""
            height="100%"
            py={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              // border: "1px solid rgba(224, 224, 224, 1)",
              // borderRadius: "8px",
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.87)",
              fontFamily: "Inter",
              fontWeight: 400,
              lineHeight: 1.43,
              // boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
              // marginTop: "10px",
            }}
          >
            <span>Apply filters to view data</span>
          </Stack>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseContextMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
            zIndex: 99999999999999,
          },
        }}
      >
        <MenuItem onClick={handleOpenInNewTab}>Open in new tab</MenuItem>
      </Menu>
    </div>
  );
};

export default BalanceSheetTable;
