import React, { useState } from "react";
import {
  Container,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../hooks/useApi";

const InviteUserPage = () => {
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const { apiCall } = useApi();

  const { enqueueSnackbar } = useSnackbar();
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    chooseOwnUserID: false,
    userID: "",
    firstName: "",
    lastName: "",
    email: "",
    nickName: "",
    phoneNumber: "",
    hasAdminPrivileges: false,
    addEmployeeRecord: false,
    allowedToInviteUsers: false,
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.checked,
    }));
  };

  const handleInputChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.termsAccepted) {
      // Updated validation
      newErrors.termsAccepted = "This field is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInvite = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const payload = {
      UserDetails: {
        xxuFirstName: formData.firstName,
        xxuLastName: formData.lastName,
        xxuNickName: formData.nickName || "",
        xxuLoginEmail: formData.email || "",
        xxuPublicEmail: formData.email || "",
        xxuPhone: formData.phoneNumber || "",
        xxlType_xxtID: -1,
      },
      HasAdminPermissionsYN: formData.hasAdminPrivileges ? "Y" : "N",
      CreateEmployeeForThisUserYN: formData.addEmployeeRecord ? "Y" : "N",
      AllowInviteNewOtherUsersYN: formData.allowedToInviteUsers ? "Y" : "N",
      SystemEmailTemplateTypeID: 4,
    };

    try {
      await apiCall({
        url: `https://${envConfig.apiDev1}/api/v3/en-au/databases/invite-user-to-business-files`,
        method: "POST",
        body: payload,
        onSuccess: (data) => {
          enqueueSnackbar("User invited successfully!", { variant: "success" });
          setFormData({
            chooseOwnUserID: false,
            userID: "",
            firstName: "",
            lastName: "",
            email: "",
            nickName: "",
            phoneNumber: "",
            hasAdminPrivileges: false,
            addEmployeeRecord: false,
            allowedToInviteUsers: false,
            termsAccepted: false,
          });
        },
        onError: (error) => {
          enqueueSnackbar(error || "An error occurred", {
            variant: "error",
          });
        },
      });
    } catch (error) {
      console.error("Error inviting user:", error);
      enqueueSnackbar("Failed to invite user", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="sm" sx={{ mb: 3 }}>
      <Typography variant="h4" gutterBottom>
        Invite User
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.chooseOwnUserID}
              onChange={handleCheckboxChange("chooseOwnUserID")}
            />
          }
          label="Choose my own User ID"
        />
        {formData.chooseOwnUserID && (
          <TextField
            fullWidth
            label="User ID"
            value={formData.userID}
            onChange={handleInputChange("userID")}
            margin="normal"
          />
        )}
        <TextField
          required
          fullWidth
          label="First Name"
          value={formData.firstName}
          onChange={handleInputChange("firstName")}
          margin="normal"
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <TextField
          required
          fullWidth
          label="Last Name"
          value={formData.lastName}
          onChange={handleInputChange("lastName")}
          margin="normal"
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <TextField
          fullWidth
          label="E-mail"
          value={formData.email}
          onChange={handleInputChange("email")}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Nick Name"
          value={formData.nickName}
          onChange={handleInputChange("nickName")}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Phone Number"
          value={formData.phoneNumber}
          onChange={handleInputChange("phoneNumber")}
          margin="normal"
        />

        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.hasAdminPrivileges}
                onChange={handleCheckboxChange("hasAdminPrivileges")}
              />
            }
            label="Has administrator privileges"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.addEmployeeRecord}
                onChange={handleCheckboxChange("addEmployeeRecord")}
              />
            }
            label="Add Employee records to this user"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.allowedToInviteUsers}
                onChange={handleCheckboxChange("allowedToInviteUsers")}
              />
            }
            label="Allowed to invite new users"
          />
        </Box>

        {/* Combined Checkbox with Hyperlinks */}
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.termsAccepted}
                onChange={handleCheckboxChange("termsAccepted")}
              />
            }
            label={
              <Typography>
                I am authorised to provide these details according to{" "}
                <a
                  className="link"
                  href="https://exacc.au/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    cursor: "pointer",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                  }}
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://exacc.au/privacy-policy"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    cursor: "pointer",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </a>
                <span style={{ color: "#D32F2F" }}>*</span>
              </Typography>
            }
          />
          {errors.termsAccepted && (
            <Typography color="error" variant="caption">
              {errors.termsAccepted}
            </Typography>
          )}
        </Box>

        <Grid
          container
          spacing={2}
          mt={2}
          sx={{
            pb: 3,
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInvite}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Invite
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default InviteUserPage;
