import React from "react";
import { ButtonOwnProps } from "@mui/material";
import { IconWrapper } from "./icon-wrapper";

export const IconPencil = ({
  color,
  palleteType,
}: {
  color: ButtonOwnProps["color"];
  palleteType?: "main" | "light" | "dark" | "contrastText";
}) => {
  return (
    <IconWrapper color={color} palleteType={palleteType}>
      {(strokeColor) => (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.65661 17.0002L6.99975 17.0002L6.99975 14.0002M6.10235 14.8976L17.4107 3.5892C18.1918 2.80815 19.4581 2.80816 20.2392 3.58921C21.0202 4.37025 21.0202 5.63658 20.2392 6.41763L8.764 17.8928C8.22794 18.4289 7.95992 18.6969 7.6632 18.9273C7.39965 19.132 7.11947 19.3143 6.8256 19.4725C6.49475 19.6504 6.14115 19.787 5.43395 20.0601L3 20.9999L3.78312 18.6503C4.05039 17.8484 4.18403 17.4475 4.3699 17.0731C4.53497 16.7406 4.73054 16.4242 4.95409 16.1278C5.20582 15.7941 5.50466 15.4953 6.10235 14.8976Z"
            stroke={strokeColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconWrapper>
  );
};
