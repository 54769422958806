import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { useBASStore, useBASStoreBase } from "../store/bas";
import { useDownloadPDFBASReport } from "../api/get-pdf-report";
import { useTheme } from "../../../../context/ThemeContext";
import {
  darkTheme,
  lightTheme,
  commonThemes,
} from "../../../../utils/themes/themes";
import { formatDateToISO } from "../../../../utils/format-date";
import { boolToYN } from "../../../../utils/format-bool";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

interface DownloadAsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const BASDownloadAsPDFDialog = ({
  isOpen,
  onClose,
}: DownloadAsProps) => {
  const { dbId } = useParams();
  const state = useBASStoreBase((state) => state);
  const showPrintDetailsYN = state.showFilters.showPrintDetailsYN;
  const setShowFilterByType = useBASStore.use.setShowFilterByType();

  const { mutateAsync: downloadAsPDF, isPending } = useDownloadPDFBASReport({
    mutationConfig: {
      onError(error) {
        enqueueSnackbar(error?.message, { variant: "error" });
      },
    },
  });

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Dialog sx={{ ">div>div": { p: 4 } }} open={isOpen} onClose={onClose}>
      <DialogTitle
        // @ts-ignore
        sx={{ ...commonThemes.popupTitle, p: 0 }}
      >
        <span>Download to PDF</span>
      </DialogTitle>
      <DialogContent sx={{ p: 0, mt: "30px" }}>
        <FormControlLabel
          key={"showPrintDetailsYN"}
          value={!!showPrintDetailsYN}
          control={
            <Checkbox
              checked={!!showPrintDetailsYN}
              onChange={() =>
                setShowFilterByType("showPrintDetailsYN", !showPrintDetailsYN)
              }
              id="showPrintDetailsYN"
            />
          }
          label={"Show Print Details"}
          // @ts-ignore
          sx={{ mr: 2, span: { ...commonThemes.normalText } }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: "30px" }}>
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(132, 159, 35, 0.04)",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          // @ts-ignore
          variant="contained"
          disabled={isPending}
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: theme.palette.primary.main,
            },
          }}
          // not ideal but whatever - keep in "sync" wtih body in GeneralLedger.tsx
          onClick={async () => {
            await downloadAsPDF({
              body: {
                AccountingMethodCA: state.accountingMethodCA,
                GstEnd: formatDateToISO(state.endDate?.toDate()),
                GstStart: formatDateToISO(state.gstStartDate?.toDate()),
                PrlStart: formatDateToISO(state.paygStartDate?.toDate()),
                PrlEnd: formatDateToISO(state.endDate?.toDate()),
                PaygInstalment: 0,
                ShowPrintDetsYN: boolToYN(state.showFilters.showPrintDetailsYN),
              },
              dbId,
            });
            onClose();
          }}
        >
          Download PDF Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface BASDownloadAsExcelDialogProps extends DownloadAsProps {
  onExporting: () => Promise<void>;
}

export const BASDownloadAsExcelDialog = ({
  onExporting,
  isOpen,
  onClose,
}: BASDownloadAsExcelDialogProps) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const downloadAsExcel = async () => {
    await onExporting();
    onClose();
  };

  return (
    <Dialog sx={{ ">div>div": { p: 4 } }} open={isOpen} onClose={onClose}>
      <DialogTitle
        // @ts-ignore
        sx={{ ...commonThemes.popupTitle, p: 0 }}
      >
        <span>Download to Excel</span>
      </DialogTitle>
      <DialogActions sx={{ p: 0, mt: "30px" }}>
        <Button
          // @ts-ignore
          variant="secondary"
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(132, 159, 35, 0.04)",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          // @ts-ignore
          variant="contained"
          // disabled={loading || loadingPDF}
          sx={{
            textTransform: "none",
            "&.MuiButtonBase-root:hover": {
              bgcolor: theme.palette.primary.main,
            },
          }}
          onClick={downloadAsExcel}
        >
          Download Excel Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};
